input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 0 #000 inset;
    transition: background-color 5000s ease-in-out 0s;
    background: transparent;
}

::-webkit-scrollbar {
    width: 8px;
    height: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #c7e2ef;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #a0bac7;
    border-radius: 10px;
}

.react-date-picker__inputGroup__input:invalid {
    background: transparent;
}

.fixed-top {
    position: inherit;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

:focus {
    outline: -webkit-focus-ring-color auto 0;
}

body {
    font-family: 'Rubik', sans-serif !important;
    overflow-x: hidden;
    min-height: 100vh;
    position: relative;
    padding-bottom: 50px;
    padding-top: 125px;
}

body.home-page {
    overflow-x: hidden;
}

select:focus {
    outline-offset: 0;
}

.btn-success.focus,
.btn-success:focus {
    box-shadow: none !important;
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none !important;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
    color: #000 !important;
    background-color: transparent !important;
    border-color: transparent !important;
}


/* MENU */

.menu-full .bg-light {
    background-color: #e7f6fd !important;
}

.menu-full.header {
    background-color: #e7f6fd;
    border-radius: 0;
    padding: 10px 27px;
}

.menu-full .navbar-dark .navbar-nav .nav-link {
    color: #2b2b2b !important;
    font-size: 14px;
    padding: 0 18px;
}

.option-in .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
    padding: 5px;
    font-size: 13px;
}

.option-in .react-calendar__month-view__weekdays__weekday {
    padding: 5px;
}

.option-in .react-calendar__navigation {
    margin-bottom: 0;
}

.option-in .react-calendar {
    width: 281px;
}

.menu-full .navbar-light .navbar-nav .nav-link {
    color: #2b2b2b !important;
    font-size: 14px;
    padding: 0 18px;
}

.menu-full .navbar-brand {
    padding: 0;
}
.menu-full .navbar-brand img {
    width: 59px;
}

.menu-full a.login img {
    margin-right: 10px;
}

.menu-full .signup-btn {
    border-radius: 30px;
    color: #fff;
    background-color: #00a6bc;
    font-size: 14px;
    border: none;
    font-weight: 500;
    padding: 6px 20px;
    background-image: linear-gradient(141deg, #00afb6 0%, #00bbaf 51%, #00c5a9 75%);
    min-width: 100px;
}

.signup-btn .nav-link {
    padding: 0;
    color: white;
}

.menu-full .signup-btn:hover {
    background-image: linear-gradient(141deg, #00c5a9 0%, #00afb6 51%, #00bbaf 75%);
    background-color: #00a6bc;
}

.menu-full button.right-button {
    border-radius: 0 30px 30px 0;
}

.menu-full button.left-button {
    border-radius: 30px 0 0 30px;
}

.menu-full a.login {
    color: #000;
    text-decoration: none;
    margin-right: 40px;
    font-size: 14px;
}

.menu-full .dropdown-toggle::after {
    display: none;
}

.menu-full .login button {
    border-radius: 30px;
    color: #fff;
    background-color: #00a6bc;
    font-size: 14px;
    border: none;
    font-weight: 600;
    padding: 8px 25px;
    background-image: linear-gradient(141deg, #00afb6 0%, #00bbaf 51%, #00c5a9 75%);
}

.menu-full .login a {
    color: #000;
    text-decoration: none;
    margin-right: 40px;
    font-size: 14px;
}

.menu-full .login a img {
    margin-right: 10px;
}

.menu-full .login button:hover {
    background-image: linear-gradient(141deg, #00c5a9 0%, #00afb6 51%, #00bbaf 75%);
    background-color: #00a6bc;
}

.menu-full .dropdown-menu.show {
    display: block;
}

.dropdown-item {
    font-size: 14px;
}

.menu-full {
    background-color: transparent;
    border-radius: 0 0 0 0;
    padding: 0 0 0 0;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    position: fixed;
    z-index: 3;
}


/* MENU */


/* SEARCH */

.search-full {
    background-color: #fff;
    padding: 12px;
    width: 100%;
    border-radius: 15px;
    box-shadow: 3px 11px 10px -8px #d7d7d7;
}

.search-full li {
    display: inline-block;
    padding: 0 10px;
    border-left: 2px solid #e7f6fd;
}

.input-cln input::placeholder {
    color: #000;
    font-weight: 700;
    font-size: 16px;
}

.search-full li:first-child {
    border-left: 0;
    padding-left: 0;
}

.input-tab {
    width: 100%;
    height: 60px;
}


/*.select.select-full::after {*/


/*    content: '\f107';*/


/*    position: absolute;*/


/*    top: -10px;*/


/*    right: 0;*/


/*    padding: 0;*/


/*    display: none;*/


/*    background: transparent;*/


/*    cursor: pointer;*/


/*    pointer-events: none;*/


/*    transition: .25s all ease;*/


/*}*/


/*.select.select-full {*/


/*    position: relative;*/


/*    display: flex;*/


/*    width: 100%;*/


/*    height: auto;*/


/*    line-height: 3;*/


/*    background: transparent;*/


/*    overflow: hidden;*/


/*    border-radius: .25em;*/


/*    margin-bottom: 0;*/


/*}*/

.select.select-full .css-yk16xz-control {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
    font-weight: normal;
}

.select.select-full .css-1fhf3k1-control {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
    font-weight: normal;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
}

.search-full .select.select-full .css-1fhf3k1-control .css-1hb7zxy-IndicatorsContainer {
    display: none;
}

.search-full .react-date-picker--disabled {
    background-color: hsl(0, 0%, 100%);
    color: hsl(0, 0%, 60%);
}

.search-full .react-date-picker--disabled input {
    color: #8994a9;
}

.search-full .react-date-picker__inputGroup {
    padding: 5px 0;
    font-size: 13px;
}

.search-full .react-date-picker__inputGroup span {
    color: hsl(0, 0%, 60%) !important;
}

.search-full .select.select-full .css-1hwfws3 {
    font-size: 13px;
    padding: 2px 0;
    font-weight: 500;
}

.search-full .css-tlfecz-indicatorContainer {
    padding: 8px 0;
}

.select.select-full .css-1wa3eu0-placeholder {
    font-weight: 300;
    font-size: 14px;
}

.select.select-full select option {
    font-size: 16px;
}

.select.select-full .css-1okebmr-indicatorSeparator {
    width: 0;
}

.react-date-picker__wrapper {
    display: flex;
    border: 0 solid #ddd;
    border-bottom-width: 1px;
    width: 100%;
}


/*.select.select-full select {*/


/*    flex: 1;*/


/*    padding: 0;*/


/*    color: #212529;*/


/*    cursor: pointer;*/


/*    border-bottom: 1px solid #B9C1CA;*/


/*    border-top: 0;*/


/*    border-left: 0;*/


/*    border-right: 0;*/


/*    font-size: 16px;*/


/*    font-weight: 700;*/


/*    background: transparent;*/


/*    font-family: 'Rubik', sans-serif;*/


/*}*/

.input-cln .btn-success {
    color: #000000;
    background-color: transparent;
    border-color: transparent;
}

.input-cln .btn-success:hover {
    color: #000000;
    background-color: transparent;
    border-color: transparent;
}

.input-cln .btn {
    padding: 0;
}

.date-change {
    width: 100%;
    height: 60px;
}

.input-cln .react-date-picker {
    width: 100%;
    padding: 9px 0 0 0;
}

.search-full ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
}

.search p {
    font-size: 12px;
    color: #828282;
    margin-bottom: 2px;
}

.search span {
    font-size: 11px;
    color: #000;
    text-transform: uppercase;
}

ul.ul-in input {
    background-color: transparent;
    border: 0 solid #ced4da;
    text-align: center;
    width: 26px !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    display: inline-block;
    font-size: 13px;
    padding: 0;
    color: #000;
    font-weight: 700;
    height: auto;
}

ul.ul-in li {
    width: auto;
    border: none;
    float: left;
}

.search-full li ul.ul-in li {
    padding: 0 8px;
}

ul.ul-in button {
    padding: 0;
    background: transparent;
}

.ul-in i {
    font-size: 10px;
    color: #6dcef0;
}

.counter-bg {
    background-color: #f4f6f9;
    display: inline-block;
    border-radius: 10px;
    padding: 0 2px;
}

.ul-in span.qty {
    margin-right: 5px;
}

.ul-in p b {
    font-size: 16px;
    color: #000;
    padding-right: 5px;
}

.ul-in p {
    color: #000;
    font-size: 13px;
}


/* SEARCH */

.shipping-line {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    margin-top: 30px;
}

.shipping-line h1 {
    font-size: 16px;
    color: #575757;
    font-weight: 600;
    font-family: 'Rubik', sans-serif;
    margin-bottom: 14px;
    margin-top: 12px;
}

.shipping-line ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.search-full li.modifysearchbtn button {
    background: #335291;
    color: #fff;
    padding: 11px 0;
    border-radius: 20px;
    vertical-align: middle;
    margin-top: 16px;
    display: inline-block;
    width: 100%;
    text-align: center;
    text-decoration: none;
    border: 0;
    cursor: pointer;
}

.select.select-full .css-107lb6w-singleValue {
    color: #8994a9;
}


/*.search-full li.modifysearchbtn button:hover {*/


/*    background: #2450ab;*/


/*}*/

.search-full li:last-child {
    border-right: 0;
    padding-right: 0;
}

.search-full li:nth-child(1) {
    width: 13%;
}

.search-full li:nth-child(2) {
    width: 13%;
}

.search-full li:nth-child(3) {
    width: 13%;
}

.search-full li:nth-child(4) {
    width: 13%;
}

.search-full li:nth-child(5) {
    width: 13%;
}

.search-full li:nth-child(6) {
    width: 25%;
}

.search-full li:nth-child(7) {
    width: 10%;
}

.search-full li ul.ul-in li:nth-child(1) {
    width: auto;
}

.search-full li ul.ul-in li:nth-child(2) {
    width: auto;
}

.search-full li ul.ul-in li:nth-child(3) {
    width: auto;
}

.search-full.modify {
    display: none;
}

.search-full.modify select {
    color: #6b7894;
}

.switch-btn svg {
    display: none;
}

.switch-btn .react-switch-bg {
    height: 11px !important;
    width: 38px !important;
    background: #cfecf6 !important;
}

.switch-btn .react-switch-handle {
    height: 14px !important;
    width: 14px !important;
    top: -2px !important;
    left: -2px !important;
    background: #51b9de !important;
    right: 2px !important;
}

.switch-btn span {
    margin-right: 10px;
    font-size: 12px;
}

.switch-btn label {
    margin-bottom: 0;
}

.search-full.modify .ul-in p b {
    color: #6b7894;
}

.search-full.modify .search span {
    color: #6b7894;
}

.search-full.modify ul.ul-in input {
    color: #6b7894;
}

.search-full.modify .ul-in p {
    color: #6b7894;
}

.search-full.modify .input-cln input::placeholder {
    color: #6b7894;
}

.search-full.modify .input-cln i {
    color: #6b7894;
}

.custom-control.material-checkbox {
    --color: #189ad3;
}

.custom-control.material-checkbox .material-control-input {
    display: none;
}

.custom-control.material-checkbox .material-control-input:checked ~ .material-control-indicator {
    border-color: var(--color);
    -webkit-transform: rotateZ(45deg) translate(1px, -5px);
    transform: rotateZ(45deg) translate(1px, -5px);
    width: 10px;
    border-top: 0 solid #fff;
    border-left: 0 solid #fff;
}

.custom-control.material-checkbox .material-control-indicator {
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 0;
    width: 16px;
    height: 16px;
    border: 2px solid #aaa;
    transition: .3s;
}

.material-control-description {
    font-size: 13px;
    font-family: 'Rubik', sans-serif !important;
}

.recent-booking {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    margin-top: 21px;
}

.recent-booking h1 {
    font-size: 16px;
    color: #575757;
    font-weight: 600;
    font-family: 'Rubik', sans-serif;
    margin-bottom: 14px;
    margin-top: 12px;
}

.recent-booking ul {
    text-decoration: none;
    list-style: none;
    padding: 0;
    margin: 0;
}

.recent-booking ul li {
    display: flex;
    margin-bottom: 20px;
}

.recent-booking ul li img {
    height: 28px;
    width: 6px;
    margin-top: 8px;
}

.view {
    display: inline-block;
    width: 37%;
    text-align: center;
}

.text-booking {
    display: inline-block;
    padding: 0 5px;
    font-size: 13px;
    width: 63%;
    color: #2b2b2b;
}

.view p {
    font-size: 12px;
    color: #22c55f;
    margin-bottom: 0;
}

.view p b {
    font-size: 13px;
}

.view a {
    background: #edf3ff;
    padding: 4px 9px;
    color: #335291 !important;
    font-weight: 700;
    font-size: 13px;
    border-radius: 10px;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
}

.grow-booking {
    margin-top: 21px;
    margin-bottom: 50px;
}

.main-listing-right {
    background: #f2f2f2;
    padding: 10px;
    border-radius: 10px;
    margin-top: 0;
    margin-bottom: 50px;
}

.routes-found ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
}

.routes-found li {
    float: left;
    font-size: 12px;
    color: #7284aa;
    padding: 10px 26px;
}

.routes-found li img {
    margin-right: 5px;
}

.routes-found li:nth-child(1) {
    color: #335291;
    font-size: 15px;
}

.routes-found li:nth-child(4) {
    color: #335291;
    font-size: 15px;
    margin-left: 15px;
}

.routes-found .btn-toggle {
    margin: -4px 0 0 28px;
    padding: 0;
    position: relative;
    border: none;
    height: 11px;
    width: 30px;
    border-radius: 1.5rem;
    color: #6b7381;
    background: #bdc1c8;
}

.routes-found .btn-toggle:focus,
.routes-found .btn-toggle.focus,
.routes-found .btn-toggle:focus.active,
.routes-found .btn-toggle.focus.active {
    outline: none;
}

.routes-found .btn-toggle:before,
.routes-found .btn-toggle:after {
    width: auto;
    text-align: center;
    font-weight: 600;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
}

.routes-found .btn-toggle:before {
    content: 'Fastest';
    left: -46px;
    font-weight: normal;
    letter-spacing: normal;
    text-transform: inherit;
    top: -3px;
}

.routes-found .btn-toggle > .handle {
    position: absolute;
    top: -1px;
    left: 0;
    width: 13px;
    height: 13px;
    border-radius: 1.125rem;
    background: #51b9de;
    transition: left 0.25s;
}

.routes-found .btn-toggle.active {
    transition: background-color 0.25s;
}

.routes-found .btn-toggle.active > .handle {
    left: 17px;
    transition: left 0.25s;
}

.routes-found .btn-toggle.btn-toggle.active:before {
    color: #335291;
}

.routes-found .btn-toggle.active:after {
    opacity: 1;
}

.routes-found .btn-toggle:before {
    color: #959595;
}

.routes-found .btn-toggle:after {
    color: #959595;
}

.routes-found .btn-toggle.active {
    background-color: #cfecf6;
}

.routes-found .btn-toggle1 {
    margin: 0 0 0 28px;
    padding: 0;
    position: relative;
    border: none;
    height: 11px;
    width: 30px;
    border-radius: 1.5rem;
    color: #6b7381;
    background: #bdc1c8;
}

.routes-found .btn-toggle1:focus,
.routes-found .btn-toggle1.focus,
.routes-found .btn-toggle1:focus.active,
.routes-found .btn-toggle1.focus.active {
    outline: none;
}

.routes-found .btn-toggle1:before,
.routes-found .btn-toggle1:after {
    width: auto;
    text-align: center;
    font-weight: 600;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
}

.routes-found .btn-toggle1:before {
    content: 'Lowest';
    left: -43px;
    font-weight: normal;
    letter-spacing: normal;
    text-transform: inherit;
    top: -3px;
}

.routes-found .btn-toggle1 > .handle1 {
    position: absolute;
    top: -1px;
    left: 0;
    width: 13px;
    height: 13px;
    border-radius: 1.125rem;
    background: #51b9de;
    transition: left 0.25s;
}

.routes-found .btn-toggle1.active {
    transition: background-color 0.25s;
}

.routes-found .btn-toggle1.active > .handle1 {
    left: 17px;
    transition: left 0.25s;
}

.routes-found .btn-toggle1.btn-toggle1.active:before {
    color: #335291;
}

.routes-found .btn-toggle1.active:after {
    opacity: 1;
}

.routes-found .btn-toggle1:before {
    color: #959595;
}

.routes-found .btn-toggle1:after {
    color: #959595;
}

.routes-found .btn-toggle1.active {
    background-color: #cfecf6;
}

.main-listing-right .table thead th {
    border-bottom: 0 solid #dee2e6;
    font-weight: bold;
}

.main-listing-right .table td,
.main-listing-right .table th {
    border-top: 0 solid #dee2e6;
    padding: 7px 14px;
}

.main-listing-right .table th {
    vertical-align: middle;
}

.main-listing-right .table th i {
    margin-right: 8px;
}

.main-listing-right .table {
    margin-bottom: 0;
}

.main-listing-right .table.table-in {
    margin-bottom: 10px;
}

.main-listing-right td {
    font-size: 13px;
}

.main-listing-right td img {
    float: left;
}

.main-listing-right td p {
    margin: 0;
    font-size: 14px;
}

.main-listing-right td p b {
    font-size: 15px;
}

.main-listing-right td span {
    font-size: 11px;
    color: #748096;
    display: inline-block;
}

.main-listing-right td button {
    padding: 8px 17px;
    display: inline-block;
    background: #335291;
    border-radius: 20px;
    color: #fff;
    margin-bottom: 0;
    text-decoration: none;
    border: none;
    cursor: pointer;
}

.main-listing-right tr th {
    color: #748096;
    font-size: 13px;
}

.main-listing-right tr th:nth-child(1) {
    text-align: right;
    width: 360px;
}

.main-listing-right tr th:nth-child(2) {
    text-align: left;
    width: 126px;
}

.main-listing-right tr th:nth-child(3) {
    width: 178px;
    text-align: left;
}

.main-listing-right tr th.changes-new:nth-child(3) {
    width: 84px;
}

.main-listing-right tr th:nth-child(4) {
    text-align: left;
    width: 178px;
}

tr.bg-tr-change td {
    background: #f6f8f9;
}

.main-listing-right table tbody {
    border-radius: 20px;
}

.main-listing-right td ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.main-listing-right td li {
    float: left;
    padding: 0 12px;
    color: #335291;
}

.main-listing-right td li img {
    float: left;
    margin-top: 8px;
}

.main-listing-right tr.bg-tr-change td li {
    font-size: 10px;
    color: #71757f;
    padding: 0 8px;
}

.main-listing-right tr.bg-tr-change td ul.border-right li {
    border-right: 1px solid #a6a9b0;
}

.main-listing-right tr.bg-tr-change td ul.border-right li:nth-child(2) {
    border-right: 0 solid #a6a9b0;
}


/* .main-listing-right tr.bg-tr-change {
    background: #f6f8f9;
    ;
} */

.main-listing-right tr.bg-tr-change td {
    vertical-align: middle;
}

.main-listing-right tr.bg-tr-change td p {
    margin: 0;
    display: inline-block;
    padding-right: 5px;
}

.main-listing-right tr.bg-tr-change td li img {
    margin: 0;
}

ul.icon-right {
    float: right;
}

.color-green {
    color: #22c55f;
}

.App {
}

.App-logo {
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #09d3ac;
}

ul.icon-right li a {
    background: transparent;
    padding: 0;
}

.images-fix-size {
    width: 36px;
    height: 36px;
    float: left;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.13);
    margin-right: 5px;
}

.images-fix-size img {
    background-size: contain;
}

.main-listing-right a.price-drop {
    font-size: 12px;
    background: transparent;
    padding: 0;
    color: #335291;
    font-weight: normal;
    display: block;
    text-decoration: none;
    margin-top: 5px;
}

td.border-left-book-now {
    border-left: 1px solid #d7e2e8;
    text-align: center;
}

td.border-left-book-now p b {
    font-size: 18px;
}

td.border-left-book-now p {
    font-size: 13px;
}

.main-listing-right td.shiping-name p {
    font-size: 15px;
}

.table-in {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 13px 16px 0 rgba(0, 0, 0, 0.06);
}

.bg-tr-change li p {
    font-size: 11px;
}

.shiping-name {
    width: 301px;
}

.table-in tbody tr td:nth-child(2) {
    text-align: center;
}

.table-in tbody tr td:nth-child(3) p {
    font-size: 11px;
    font-weight: bold;
}

.table-in tbody tr td:nth-child(4) p {
    font-size: 11px;
    font-weight: bold;
}

.table-in tbody tr td:nth-child(5) p {
    font-size: 11px;
    font-weight: bold;
}

.table-in tbody tr td:nth-child(6) {
    text-align: center;
    margin: 0 auto;
}

.main-listing-right td.main-td {
    padding: 0;
}

tr.bg-tr-change td:nth-child(3) {
    border-radius: 0 0 10px 0;
}

tr.bg-tr-change td:nth-child(1) {
    border-radius: 0 0 0 10px;
}

.grow-booking img {
    width: 100%;
}


/* homepage */


/* .banner-left-text span {
    white-space: pre;
} */

body:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.container-fluid.co-p {
    /* padding: 0 50px;
    height: auto;
    vertical-align: middle;
    top: 50%;
    transform: translateY(-50%);
    position: inherit;
    right: 0;
    left: 0; */
    margin: 50px auto 0;
}

.menu-full.co-p {
    padding: 24px 0 48px 0;
    background: #e7f6fd;
}

.form-control:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.menu-full .bg-dark {
    background-color: #fff !important;
}

.menu-full .navbar-dark .navbar-nav .active > .nav-link,
.menu-full .navbar-dark .navbar-nav .nav-link.active,
.menu-full .navbar-dark .navbar-nav .nav-link.show,
.menu-full .navbar-dark .navbar-nav .show > .nav-link {
    color: #2b2b2b;
}

.menu-full .navbar-dark .navbar-nav .nav-link {
    color: #2b2b2b !important;
    font-size: 14px;
    padding: 0 18px;
}


/*.menu-full .navbar {*/


/*    position: fixed;*/


/*    width: 100%;*/


/*    z-index: 1030;*/


/*}*/

.menu-full ul {
    margin-top: 0;
    margin-left: 71px;
}

.menu-full .login a {
    color: #000;
    text-decoration: none;
    margin-right: 40px;
    font-size: 14px;
}

.menu-full .login a img {
    margin-right: 10px;
}

.menu-full .login button {
    border-radius: 30px;
    color: #fff;
    background-color: #00a6bc;
    font-size: 14px;
    border: none;
    font-weight: 600;
    padding: 8px 25px;
    background-image: linear-gradient(141deg, #00afb6 0%, #00bbaf 51%, #00c5a9 75%);
}

.menu-full .login button:hover {
    background-image: linear-gradient(141deg, #00c5a9 0%, #00afb6 51%, #00bbaf 75%);
    background-color: #00a6bc;
}

.banner-left-text h1 {
    font-size: 61px;
    font-family: 'Rubik', sans-serif;
    font-weight: 700;
    text-align: left;
}

.banner-left-text p {
    font-family: 'Rubik', sans-serif;
    font-weight: 200;
    font-size: 16px;
}

.banner-left-text {
    padding-top: 0;
    height: 100%;
    vertical-align: middle;
    width: 100%;
}

.banner-tab-view {
    padding-top: 0;
    height: 100%;
    vertical-align: middle;
}

.banner-tab-view .nav-link {
    display: block;
    padding: 6px 30px;
    font-size: 14px;
    color: #9d9d9d;
    font-family: 'Rubik', sans-serif;
    font-weight: 700;
}

.banner-tab-view .card {
    border: 0 solid rgba(0, 0, 0, .125);
}

.banner-tab-view .card-header {
    padding: 0;
    border-bottom: 0 solid rgba(0, 0, 0, .125);
    background-color: transparent;
    box-shadow: 4px 3px 11px -4px #ddd;
    border-radius: 30px;
    width: auto;
    text-align: center;
    margin: 0 auto;
}

.banner-tab-view .card-header:first-child {
    border-radius: 30px;
}

.banner-tab-view .card-header-tabs {
    /*margin-right: 0;*/
    /*margin-bottom: 0;*/
    /*margin-left: 0;*/
    background: #fff;
    border-bottom: 0;
    border-radius: 30px;
    text-align: center;
    margin: 0 auto;
}

.banner-tab-view .nav-tabs .nav-item.show .nav-link,
.banner-tab-view .nav-tabs .nav-link.active {
    color: #335291;
    background-color: #daf5ff;
    border-color: transparent;
    border-radius: 30px 0 0 30px;
    padding: 6px 30px;
}

.banner-tab-view .nav-link.change-bg.active {
    border-radius: 0 0 0 0;
}

.banner-tab-view .nav-link.change-bg-last.active {
    border-radius: 0 30px 30px 0;
}

.banner-tab-view .nav-item img {
    margin-right: 14px;
    margin-top: -3px;
}

.banner-tab-view .nav-tabs .nav-link:focus,
.banner-tab-view .nav-tabs .nav-link:hover {
    border-color: transparent;
}

.banner-tab-view .tab-content {
    width: 820px;
    margin: 0 auto;
    display: flex;
}

.banner-tab-view .tab-content > .tab-pane {
    margin: 0 auto;
    width: 100%;
}

.box-count ul {
    list-style: none;
    margin: 0 auto 0;
    padding: 0;
    width: 100%;
}

.box-count ul li h5 {
    font-size: 16px;
}

.box-count ul li {
    float: left;
    border: 1px solid #ddd;
    margin: 5px;
    padding: 6px 6px;
    width: 21%;
    height: auto;
}

.box-count ul li:hover {
    border: 1px solid #335291;
}

.box-count li .input-group p {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 500;
}

.box-count li .input-group span.icon {
    width: 100%;
    text-align: center;
    color: #ddd;
    font-size: 16px;
    font-weight: normal;
    padding: 1px 0;
}

.box-count .input-group input {
    background-color: #fff;
    border: 0 solid #ced4da;
    text-align: center;
    width: 26px !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    padding: 0;
}

.box-count .input-group .btn {
    border: 0 solid transparent;
    padding: 0;
    font-size: 9px;
    line-height: 0;
    border-radius: 0;
    background: transparent;
    color: #335291;
}

.box-count ul li:first-child {
    border: 0 solid #ddd;
}

.count-div {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 0;
}

.option-form {
    width: 100%;
    display: flex;
    margin-top: 30px;
}

.banner-tab-view .card {
    background: transparent;
}

.option-in select {
    width: 100%;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    font-size: 18px;
    color: #b1b1b1;
    font-weight: 500;
    padding-bottom: 5px;
    -webkit-appearance: none;
}

.option-in select option {
    font-size: 14px;
    border: 0;
    box-shadow: 10px 10px 10px 10px #000;
    background: #fff;
    display: inline-block;
    border-right: 0;
    border-bottom: 0;
}

select:focus {
    outline: none;
    -moz-appearance: none;
}

.option-in .input-group-text {
    background-color: transparent;
    border: 0 solid #ced4da;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
}

.search-btn a {
    text-decoration: none;
    background: #335291;
    color: #fff !important;
    padding: 10px 45px;
    border-radius: 30px;
    margin: 0 auto;
    cursor: pointer;
}

.search-btn {
    display: flex;
    margin-top: 36px;
    z-index: 1030;
}

.search-btn a:hover {
    color: #fff;
    text-decoration: none;
}

.banner-left-text h1 span {
    color: #6ecef1;
    text-transform: uppercase;
}

.banner-left-text ul {
    padding: 0;
    margin: 0;
    margin-top: 28px;
    list-style: none;
}

.banner-left-text ul li {
    float: left;
    width: 50%;
    margin: 6px 0;
    font-size: 14px;
    text-align: left;
}

.banner-left-text i {
    color: #6ecef1;
    margin-right: 8px;
}

.icon-form {
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

.icon-form img {
    margin: 0 auto;
    vertical-align: middle;
    margin-top: 15px;
}

.water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
}

.water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
}

.water ul.waves li {
    background-repeat: repeat-x;
}

.water ul.waves li.wave-one {
    background-image: url("../../assets/images/wave1.png");
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0;
    height: 23px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
}

.water ul.waves li.wave-two {
    background-image: url("../../assets/images/wave2.png");
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0;
    height: 45px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
}

.water ul.waves li.wave-three {
    background-image: url("../../assets/images/wave3.png");
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 64px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
}

.water ul.waves li.wave-four {
    background-image: url("../../assets/images/wave4.png");
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 81px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
}


/*.boat {*/


/*    animation: boat 70s linear infinite;*/


/*    background-repeat: no-repeat;*/


/*    -webkit-animation-fill-mode: forwards;*/


/*    -o-animation-fill-mode: forwards;*/


/*    animation-fill-mode: forwards;*/


/*    -moz-transform: translateX(-50%);*/


/*    -webkit-transform: translateX(-50%);*/


/*    transform: translateX(-50%);*/


/*    !* Apply animation to this element *!*/


/*    bottom: 41px;*/


/*    height: 105px;*/


/*    left: 0;*/


/*    position: absolute;*/


/*    right: 0;*/


/*}*/


/*.boatwave {*/


/*    background-image: url("../../assets/images/boat2.png");*/


/*    animation: boatwave 6s linear infinite;*/


/*    background-repeat: no-repeat;*/


/*    bottom: 18px;*/


/*    height: 105px;*/


/*    left: 0;*/


/*    position: absolute;*/


/*    right: 0;*/


/*    -webkit-animation-fill-mode: forwards;*/


/*    -o-animation-fill-mode: forwards;*/


/*    animation-fill-mode: forwards;*/


/*}*/

.box-count .input-group input#datepicker {
    background-color: #fff;
    border: 0 solid #b1b1b1;
    text-align: left;
    width: 27px !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    padding: 0;
    border-bottom: 1px solid #b1b1b1;
    font-size: 16px;
    color: #b1b1b1;
}

.ui-widget.ui-widget-content {
    border: 1px solid #c5c5c5;
    z-index: 1030 !important;
}

.menu-full .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: 0 solid transparent;
    border-bottom: 0;
    border-left: 0 solid transparent;
}

.menu-full i {
    font-size: 16px;
    vertical-align: middle;
    /*margin-top: 2px;*/
}


/*Animation Keyframe Section*/

@keyframes wave {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 1920px 0;
    }
}


/* Move it (define the animation) */


/*@-moz-keyframes boat {*/


/*    0% {*/


/*        -moz-transform: translateX(-18%);*/


/*        background-position: 0 0;*/


/*    }*/


/*    100% {*/


/*        -moz-transform: translateX(100%);*/


/*    }*/


/*}*/


/*@-webkit-keyframes boat {*/


/*    0% {*/


/*        -webkit-transform: translateX(-18%);*/


/*        background-position: 0 0;*/


/*    }*/


/*    100% {*/


/*        -webkit-transform: translateX(100%);*/


/*    }*/


/*}*/


/*@keyframes boat {*/


/*    0% {*/


/*        -moz-transform: translateX(-18%);*/


/*        !* Firefox bug fix *!*/


/*        -webkit-transform: translateX(-18%);*/


/*        !* Firefox bug fix *!*/


/*        transform: translateX(-18%);*/


/*        background-position: 0 0;*/


/*    }*/


/*    100% {*/


/*        -moz-transform: translateX(100%);*/


/*        !* Firefox bug fix *!*/


/*        -webkit-transform: translateX(100%);*/


/*        !* Firefox bug fix *!*/


/*        transform: translateX(100%);*/


/*    }*/


/*}*/

@keyframes boatwave {
    0% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(-1.5deg);
    }
    100% {
        transform: rotate(0);
    }
}

#counter-stats {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 1030;
    text-align: center;
    left: 0;
    right: 0;
}

.counting {
    color: #fff;
    display: inline-block;
    font-size: 36px;
}

.stats span {
    color: #335291;
    display: inline-block;
    font-size: 28px;
    font-weight: 500;
}

#counter-stats h5 {
    color: #3f4243;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
}

.boat-full {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
}

.boat-full ul.boat-inn {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.option-in .css-yk16xz-control {
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    border-top: 0;
    border-right: 0;
    border-left: 0;
}

.option-in .css-1okebmr-indicatorSeparator {
    width: 0;
}

.option-in .react-date-picker {
    display: inherit;
    position: relative;
    width: 100%;
}

.option-in .react-date-picker__wrapper {
    border: 0 solid #cccccc;
    border-bottom-width: 1px;
    padding: 5px 0;
}

.menu-full .search ul {
    margin-top: 0;
    margin-left: 0;
}

.option-in .css-1pahdxg-control:hover {
    border-color: transparent;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid #ddd;
    border: 0;
}

.option-in .css-1pahdxg-control {
    border-color: transparent;
    box-shadow: none;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
}

.search-full .css-1pahdxg-control:hover {
    border-color: #ddd;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid #ddd;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.search-full .css-1pahdxg-control {
    border-color: transparent;
    box-shadow: none;
    border-bottom: solid #ddd;
    border-radius: 0;
    border-width: 1px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.increment-container,
.decrement-container {
    font-size: 16px;
    line-height: 0;
    padding: 0 10px;
    cursor: pointer;
}

.count-div .fa-times {
    font-size: 20px;
}

.home-error {
    margin: 5px 0;
    text-align: center;
    color: red;
    display: block;
    font-size: 12px;
}


/*confirm-booking*/

.confirm-booking {
    text-align: center;
}

.confirm-booking h1 {
    color: #335291;
    font-size: 26px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 0;
    letter-spacing: 1px;
}

.confirm-booking a {
    text-decoration: none;
    color: #3f93be;
    font-size: 14px;
    margin: 10px 0;
}

.view-deal-full-page {
    background: #e7f6fd;
    padding: 25px 0 18px 0;
    border-radius: 0 0 30px 30px;
}

.confirm-booking-table {
    margin-top: 41px;
}

.confirm-booking-table table {
    width: 74%;
    margin: 0 auto;
    text-align: left;
    border-radius: 10px;
    background: #f2f5f6;
}

.confirm-booking-table table ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.confirm-booking-table span {
    font-size: 11px;
    color: #335291;
    text-transform: uppercase;
}

.confirm-booking-table p {
    font-size: 18px;
    color: #335291;
}

.confirm-booking-table li {
    color: #4a5b7c;
    font-size: 11px;
}

.confirm-booking-table td:nth-child(2) {
    text-align: center;
}

.confirm-booking-table td:nth-child(2) p {
    color: #179ad2;
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 0;
}

.confirm-booking-table td:nth-child(2) img {
    margin: 0 auto;
    text-align: center;
}

.confirm-booking-table h1 {
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0;
}

.confirm-booking-table td:nth-child(4) {
    text-align: center;
    border-left: 1px solid #c4d2d7 !important;
}

.confirm-booking-table td:nth-child(4) p {
    margin-bottom: 0;
    color: #22c55f;
    font-size: 14px;
    font-weight: bold;
}

.confirm-booking-table td:nth-child(4) b {
    padding-left: 3px;
}

.confirm-booking-table h3 {
    font-size: 11px;
    color: #3d7691;
    margin-bottom: 31px;
}

.confirm-booking-table button {
    padding: 15px 40px;
    color: #fff;
    background: #335291;
    text-decoration: none;
    border-radius: 26px;
    box-shadow: 0 14px 16px 0 rgba(0, 0, 0, 0.18);
    display: inline-block;
    border: none;
    cursor: pointer;
}

.confirm-booking-table a:hover {
    background: #3d5c9a;
}

.confirm-booking-table .table-bordered td {
    border: 0 solid #dee2e6;
}

.confirm-booking-table .table thead th {
    vertical-align: bottom;
    border-bottom: 0 solid #dee2e6;
    padding-bottom: 0;
    font-size: 11px;
    padding-left: 6px;
    text-transform: uppercase;
}

.confirm-booking-table .table-bordered th {
    border: 0 solid #dee2e6;
}

.images-fix {
    width: 36px;
    height: 36px;
    float: left;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.13);
    margin-right: 5px;
}

.images-fix img {
    background-size: contain;
}

.confirm-booking-table tbody tr:nth-child(2) td p {
    font-size: 13px;
    margin-bottom: 0;
    padding: 10px 0;
}

.confirm-booking-table tbody tr:nth-child(2) td {
    /* border-bottom: 1px solid #dee2e6; */
}

.confirm-booking-table tbody tr:nth-child(2) td:nth-child(2) {
    border-left: 1px solid #dee2e6;
}

.confirm-booking-table table.table-booking-inn {
    width: 100%;
}

.confirm-booking-table .counter-bg button {
    padding: 0;
    background: transparent;
    box-shadow: none;
}

.confirm-booking-table .counter-bg {
    background-color: #ffffff;
    display: inline-block;
    border-radius: 10px;
    padding: 0 7px;
    margin-left: 15px;
}

.confirm-booking-table .counter-bg input {
    background-color: transparent;
    border: 0 solid #ced4da;
    text-align: center;
    width: 26px !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    display: inline-block;
    font-size: 17px;
    padding: 0;
    color: #000;
    font-weight: normal;
    height: auto;
}

.confirm-booking-table .counter-bg i {
    font-size: 9px;
    vertical-align: middle;
    margin-top: -4px;
    color: #6dcef0;
}

.confirm-booking-table .counter-bg button.btn {
    font-size: inherit;
}

.table-booking-inn td:nth-child(3) {
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    vertical-align: middle;
    /* border-bottom: 1px solid #ddd; */
}

td.tr-new-table {
    padding: 0;
}

.table-booking-inn td:nth-child(2) {
    text-align: right;
}

.table-booking-inn td:nth-child(1) {
    width: 70%;
}

.confirm-booking-table .table-booking-inn tr:nth-child(2) td:nth-child(2) {
    border: 0;
    padding: 5px 10px;
}

.confirm-booking-table .table-booking-inn tr:nth-child(2) td:nth-child(1) {
    border: 0;
}

.confirm-booking-table .table-booking-inn tr:nth-child(2) td:nth-child(3) {
    border: 0;
}

.bookin-gquote-heading {
    width: 100%;
    margin-bottom: 40px;
}

.table-booking-inn td {
    padding: 5px 10px;
}

.bookin-gquote-heading h1 {
    color: #668694;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
}

.your-booking-quote-full {
    width: 74%;
    margin: 25px auto;
}

.booking-includes {
    border-radius: 12px;
    box-shadow: 0 13px 16px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px #c9f6d2;
    background-color: #ffffff;
    position: relative;
    padding: 30px;
    min-height: 302px;
    margin-bottom: 43px;
}

.booking-includes.booking-excludes {
    border: solid 1px #fcdfe2;
}

.booking-includes.booking-excludes .incliude-heading h3 {
    text-align: center;
    color: #cd6b75;
}

.booking-includes.booking-excludes .incliude-heading {
    border-radius: 20px;
    background-color: #fff2f3;
}

.incliude-heading {
    border-radius: 20px;
    background-color: #e2fee8;
    text-align: center;
    margin: 0 auto;
    position: absolute;
    padding: 10px 30px;
    top: -20px;
    right: 0;
    left: 0;
    width: 137px;
}

.incliude-heading h3 {
    font-size: 14px;
    font-weight: bold;
    color: #5da46b;
    margin: 0;
    text-transform: uppercase;
}

.booking-includes ul {
    padding: 0 0 0 20px;
    margin: 0;
}

.booking-includes li {
    color: #84d695;
    list-style: disc outside;
    font-size: 20px;
}

.booking-includes li span {
    color: #000;
    font-size: 14px;
}

.booking-includes.booking-excludes li {
    color: #f05664;
}

.booking-includes.booking-excludes li span {
    color: #979696;
    font-size: 14px;
}

.term-conditions p {
    color: #656d70;
    font-size: 12px;
}

.term-conditions h3 {
    color: #4e76a4;
    font-size: 16px;
}

.term-conditions-text {
    margin-bottom: 5px;
}

.class-btn-new a {
    background: transparent;
    box-shadow: none;
    padding: 0;
    color: #000;
}

.class-btn-new li {
    float: left;
    margin: 0 2px;
    overflow: hidden;
    cursor: pointer;
}

table.hazardous-table {
    width: 100%;
}

table.hazardous-table td {
    padding: 0;
}

.hazardous-table td:nth-child(1) {
    width: 45%;
}

.class-btn-new span.triangle {
    position: absolute;
    margin: auto;
    top: 5px;
    left: 0;
    right: 0;
    width: 25px;
    height: 25px;
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    border-right: 1px solid #becfe0;
    border-bottom: 1px solid #becfe0;
    border-radius: 0;
}

.class-btn-new li:hover span.triangle {
    position: absolute;
    margin: auto;
    top: 5px;
    left: 0;
    right: 0;
    width: 25px;
    height: 25px;
    border-radius: 0;
    background: rgba(66, 106, 189, 0.75);
}

.class-btn-new li .active span.triangle {
    position: absolute;
    margin: auto;
    top: 5px;
    left: 0;
    right: 0;
    width: 25px;
    height: 25px;
    border-radius: 0;
    background: #335291;
}

.class-btn-new li:hover span,
.class-btn-new li .active span {
    color: #fff;
}

.class-btn-new span {
    top: 4px;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    color: #899cc2;
    font-weight: bold;
    font-size: 10px;
}

.class-btn-new {
    padding: 0;
}

li .container1 {
    width: 33px;
    height: 17px;
    position: relative;
    border-bottom: 1px solid #becfe0;
    overflow: hidden;
}

.hazardous-table span.material-control-description {
    font-weight: bold;
    color: #000;
    text-transform: capitalize;
    font-size: 14px;
}

.hazardous-table td p {
    float: left;
    margin-right: 5px;
}

.hazardous-table tr.cargo-value td:nth-child(3) p {
    text-align: right;
    float: none;
    margin: 0;
    padding: 0;
    color: #2596be;
}

.hazardous-table input {
    width: 100px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #959aa4;
    background: transparent;
    text-align: right;
}

.hazardous-table tr.cargo-value td:nth-child(2) p {
    padding: 0;
    margin: 0 17px 0 0;
    text-align: right;
    float: none;
    display: inline-block;
}

table.hazardous-table tr.cargo-value td:nth-child(2) {
    padding: 0;
}

.quote-includes li i.color-changes {
    color: #ef5563;
    font-size: 12px;
    margin-right: 5px;
}

.select.select-full .css-1fhf3k1-control {
    border-bottom: 1px solid transparent;
}

.react-date-picker--disabled .react-date-picker__wrapper {
    border-bottom: 1px solid transparent;
}

.css-2613qy-menu .Select-value-label {
    font-size: 14px !important;
}

.modify-container-type.ul-in p {
    color: #6c7993;
}

.modify-container-type.ul-in p b {
    color: #6c7993;
}

.modify-container-type.ul-in span.qty {
    color: #6c7993;
    margin-right: 14px;
}

.modify-container-type .counter-bg {
    background-color: transparent;
    display: inline-block;
    border-radius: 10px;
    padding: 0 2px;
}

.modify-container-type .counter-bg span {
    display: none;
}

.search-container-type i.class-serach {
    display: none;
}

.modify-container-type i.class-serach {
    color: #6c7993;
    padding: 0 4px;
}

.modify-container-type.ul-in input {
    color: #6c7993;
    border: none;
    background: transparent;
}

span.modify-port-country {
    color: #6c7993;
}

.search-full li.modifysearchbtn button.modify-button {
    background: #6189da;
    color: #fff;
}


/*.search-full li.modifysearchbtn button.modify-button:hover {*/


/*    background: #3056a5;*/


/*}*/

.search-select {
    font-size: 12px;
}

.option-in .search-select1 {
    font-size: 12px;
}

.option-in .home-select {
    font-size: 12px;
}

.option-in .css-1wa3eu0-placeholder {
    font-size: 16px;
}

.pop-up-login .text-model {
    padding: 20px;
    float: right;
    background: url(../../assets/images/popuo-img.jpg) no-repeat 0 -36px;
    position: relative;
    border-radius: 20px 0 0 20px;
    margin-right: -16px;
    background-position: inherit;
    min-height: 582px;
    background-size: cover;
}

.pop-up-login .text-model h1 {
    color: #fff;
    font-size: 30px;
}

.pop-up-login .text-model p {
    color: #fff;
    font-size: 13px;
    min-height: 32px;
}

.modal-dialog {
    max-width: 700px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) !important;
    left: 0;
    right: 0;
}

.pop-up-login .modal-body {
    padding: 0;
}

.pop-up-login .text-model img {
    display: inline-block;
    text-align: right;
    right: 25px;
    bottom: 30px;
    position: absolute;
}

.form-model h1 {
    font-size: 40px;
    color: #335291;
    font-weight: bold;
    text-align: center;
    width: 100%;
}

.form-model h1 a {
    text-decoration: none;
    color: #6ecef1;
    font-size: 28px;
}

.form-model h1 span {
    text-decoration: none;
    color: #6ecef1;
    font-size: 28px;
    margin-right: 5px;
    cursor: pointer;
}

.form-model ul {
    padding: 0;
    margin: 0 auto;
    list-style: none;
    background: #fff;
    box-shadow: 4px 3px 11px -4px #ddd;
    border-radius: 30px;
    display: inline-block;
}

.form-model ul li {
    display: inline-block;
    padding: 7px 23px;
    border-radius: 30px 0 0 30px;
}

.form-model ul li span {
    text-decoration: none;
    color: #9d9d9d;
    font-size: 14px;
    cursor: pointer;
}

.form-model ul li.active-tab.forwarder-tb {
    border-radius: 0 30px 30px 0;
}

.form-model ul li span img {
    margin-right: 5px;
}

.form-model ul li.active-tab {
    background: #ecfaff;
}

.form-model ul li.active-tab span {
    color: #335291;
}


/* Nao */

.form-model .input--nao {
    overflow: hidden;
    padding-top: 1em;
}

.form-model .input__field--nao {
    padding: 16px 0 0 0;
    width: 100%;
    background: transparent;
    color: #335291;
    font-size: 14px;
    border: none;
}

.form-model .input__label--nao {
    position: absolute;
    top: 15px;
    font-size: 13px;
    left: 0;
    display: block;
    width: 100%;
    text-align: left;
    padding: 0;
    pointer-events: none;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: -webkit-transform 0.2s 0.15s, color 1s;
    transition: transform 0.2s 0.15s, color 1s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.form-model .graphic--nao {
    stroke: #92989e;
    pointer-events: none;
    -webkit-transition: -webkit-transform 0.7s, stroke 0.7s;
    transition: transform 0.7s, stroke 0.7s;
    -webkit-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
}

.form-model .input__field--nao:focus + .input__label--nao,
.form-model .input--filled .input__label--nao {
    color: #333;
    -webkit-transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);
    transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);
}

.form-model .input__field--nao:focus ~ .graphic--nao,
.form-model .input--filled .graphic--nao {
    stroke: #333;
    -webkit-transform: translate3d(-66.6%, 0, 0);
    transform: translate3d(-66.6%, 0, 0);
}

.form-model .input {
    position: relative;
    z-index: 1;
    display: inline-block;
    margin: 10px 0;
    /* max-width: 350px; */
    width: 100%;
    vertical-align: top;
}

.form-model .graphic--nao {
    stroke: #92989e;
    pointer-events: none;
    transition: stroke 1s, -webkit-transform 1s;
    transition: transform 1s, stroke 1s;
    transition: transform 1s, stroke 0.9s, -webkit-transform 1s;
    transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
}

.form-model .graphic {
    position: absolute;
    top: 0;
    left: 0;
    fill: none;
}

.pop-up-login .p-left-0 {
    padding-left: 0;
}

.pop-up-login .p-right-0 {
    padding-right: 0;
}

.pop-up-login .modal-header {
    padding: 0;
    border-bottom: 0 solid #e9ecef;
}

.pop-up-login .modal-header .close {
    padding: 0;
    margin: 0;
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 27px;
    font-weight: 100;
    z-index: 1030;
}

.form-model {
    background: #fff;
    border-radius: 20px;
    border: 1px solid #ddd;
    padding: 10px;
    min-height: 582px;
    position: relative;
}

.pop-up-login .modal-content {
    border: 0 solid rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: transparent;
}

.submit-btn button {
    background: #335291;
    color: #fff;
    padding: 10px 45px;
    border-radius: 20px;
    margin: 10px auto 0;
    text-align: center;
    border: 0;
    cursor: pointer;
}

.sign-in-btn {
    text-align: center;
}

.sign-up-btn {
    text-align: center;
    margin-bottom: 0;
}

.forgot-password-btn {
    text-align: center;
    margin-bottom: 194px;
}

.modal-backdrop.show {
    opacity: 0.9;
}

.form-model p {
    text-align: center;
    font-size: 14px;
    width: 100%;
}

.forgot-password {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 13px;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.forgot-password button {
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    color: #335291;
    font-size: 12px;
    text-align: center;
    width: 100%;
}

.submit-btn button:hover {
    background: #245bca;
}

.container-drop-down {
    margin: 5px;
    padding: 3px 8px;
    border-color: #cccccc;
    border-radius: 5px;
}

.search-input-fields {
    width: 100%;
    margin: 5px;
    padding: 3px 8px;
    border-color: #cccccc;
    border-radius: 5px;
}


/*history-heading*/

.history-heading {
    background: #e7f6fd;
    border-radius: 0 0 50px 50px;
    position: fixed;
    z-index: 1;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    top: 70px;
}

.my-account-heading .breadcrumb {
    background-color: transparent;
    text-align: center;
    display: inherit;
    margin-bottom: 0;
    padding: 0 0 10px 0;
}

.my-account-heading .breadcrumb-item {
    text-align: center;
    display: inline-block;
}

.my-account-heading ol li {
    font-size: 13px;
}

.my-account-heading ol li a {
    font-size: 13px;
    color: #7aa9c1;
    text-decoration: none;
}

.my-account-heading .breadcrumb-item.active {
    color: #3f93be;
}

.my-account-heading .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: #6c757d;
    content: ">";
}

.history-nev ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline-block;
    text-align: center;
    width: 100%;
}

.history-nev ul li {
    display: inline-block;
    text-align: center;
}

.history-nev ul li a.active {
    color: #335291;
    border-bottom: 2px solid #335291;
}

.history-nev ul li a {
    padding: 10px 26px;
    display: inline-block;
    color: #8895af;
    font-size: 13px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
}

.serach-box-booking {
    background: #f6f6f6;
    border-radius: 20px;
    padding: 8px;
    margin-top: 15px;
    margin-bottom: 50px;
}

.serach-box-booking-full-scroll {
    overflow-x: hidden;
    height: 800px;
    min-height: 800px;
    overflow-y: scroll;
    padding: 5px;
}

.serach-box-booking a {
    text-decoration: none;
}

.booking-bl {
    background: #fff;
    padding: 6px 8px;
    border-radius: 10px 10px 0 0;
    box-shadow: 6px 0 15px -19px #000;
}

.booking-bl h3 {
    font-size: 12px;
    margin-bottom: 15px;
}

.booking-bl b {
    width: 100%;
    text-align: left;
    color: #335291;
    font-size: 14px;
    font-weight: 500;
}

.booking-bl h3 b {
    color: #000;
    font-size: 13px;
}

.booking-bl p {
    font-size: 12px;
    color: #26a1d5;
    font-weight: 500;
}

.booking-bl span {
    text-align: left;
    font-size: 11px;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    color: #335291;
}

.booking-bl h6 {
    font-size: 11px;
}

.booking-bl h6 b {
    font-size: 11px;
    display: inherit;
}

.booking-bl .boat-day {
    text-align: center;
}

.booking-bl .boat-day img {
    margin: 0 auto;
    text-align: center;
}

.booking-bl .boat-day p {
    text-align: center;
}

.booking-bl .booking-name ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.booking-bl .booking-name ul li p {
    font-size: 10px;
    color: #4a5b7c;
    display: inline-block;
    font-weight: normal;
    margin-bottom: 0;
    margin-right: 5px;
}

.booking-bl .booking-name ul li b {
    font-size: 10px;
    color: #4a5b7c;
    width: auto;
}

.auth-error {
    font-size: 12px;
    color: red;
    text-align: center;
    width: 100%;
    min-height: 12px;
}


/* css for button loader - RAVINDRA */

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 10px;
}

.lds-ellipsis div {
    position: absolute;
    /*top: 27px;*/
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
}


/* css for button loader ENDS */

.booking-highlight {
    background: #edfff0;
    padding: 5px 5px;
    border-radius: 0 0 10px 10px;
    box-shadow: 4px 5px 11px -11px #000;
}

.booking-highlight p {
    margin: 0;
    font-size: 11px;
    color: #237e32;
    font-weight: 500;
}

.all-box {
    margin-bottom: 15px;
    cursor: pointer;
}

.all-box .booking-highlight.cro-line {
    background: #fff8f0;
}

.all-box .booking-highlight.cro-line p {
    color: #886741;
}

.booking-bl h3 span {
    font-size: 9px;
    color: #fff;
    background: red;
    float: right;
    display: inline-block;
    width: auto;
    padding: 3px 6px;
    border-radius: 20px;
    font-family: 'Rubik', sans-serif;
}

.booking-bl h3 span b {
    color: #fff;
    font-size: 10px;
    font-family: 'Rubik', sans-serif;
}

.booking-history-heading {
    display: flex;
    background: #335292;
    padding: 8px 18px;
    border-radius: 20px;
    /*margin-top: 30px;*/
}

.booking-history-heading h6 {
    margin-right: auto;
    display: inline-block;
    margin-bottom: 0;
    color: #fff;
    font-size: 13px;
    font-weight: normal;
}

.booking-history-heading h6 b {
    font-weight: 500;
    font-size: 14px;
}

.booking-history-heading h5 {
    display: inline-block;
    margin: 0;
    color: #fff;
    font-size: 13px;
    font-weight: normal;
}

.update-booking {
    background: #e7f6fd;
    padding: 8px 10px;
    border-bottom: 2px solid #e5e5e5;
    border-radius: 8px;
    margin-top: 15px;
    margin-bottom: 20px;
}

.booing-status ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.booing-status ul li {
    display: inline-block;
    padding-right: 21px;
}

.booing-status ul li p {
    margin: 0;
    font-size: 11px;
    color: #748096;
    font-weight: 500;
}

.booing-status ul li b {
    font-size: 14px;
    color: #d27a24;
    font-weight: 500;
}

.booing-status ul li b.issued-color {
    font-size: 14px;
    color: #26a957;
}

.full-list-booking {
    padding: 0 16px;
}

.bl-btn ul {
    padding: 0;
    margin: 5px 0 0 0;
    list-style: none;
    text-align: right;
}

.bl-btn ul li {
    display: inline-block;
}

.bl-btn ul li button {
    padding: 5px 10px;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    border: none;
    background: #335292;
    font-size: 13px;
    margin: 4px 1px;
}

.bl-btn ul li button:hover {
    background: #3661b9;
}

.table-booking-list .confirm-booking-table table {
    width: 100%;
    border-radius: 0;
    background: #fff;
}

.table-booking-list .confirm-booking-table td.total {
    border-top: 0 solid #dee2e6 !important;
    /* border-bottom: 1px solid #dee2e6 !important; */
    color: #335291;
}

.table-booking-list .confirm-booking-table td.total-amount {
    border-top: 0 solid #dee2e6 !important;
    /* border-bottom: 1px solid #dee2e6 !important; */
    color: #22c55f;
    font-size: 12px;
}

.table-booking-list .confirm-booking-table td.total-amount b {
    font-size: 18px;
}

.table-booking-list table.hazardous-table td {
    padding: 4px 0;
}

.table-booking-list .confirm-booking-table .table thead th {
    border: none;
}

.table-booking-list .confirm-booking-table .table tbody td {
    border-top: none;
}

.table-booking-list .hazardous-table p {
    text-align: right !important;
    width: 100%;
    padding: 0 !important;
}

.table-booking-list .table-booking-inn td.ft-count {
    /* width: 450px; */
    /* border-bottom: 1px solid #ddd; */
}

.table-booking-list .table-booking-inn td.hozara-dous {
    /* width: 450px; */
    /* border-bottom: 1px solid #ddd;*/
}

.table-booking-inn tr:nth-child(2) td.class-select:nth-child(2) p {
    text-align: right !important;
}

.table-booking-list .table-booking-inn td.discount {
    width: 503px;
    text-align: left;
}

.table-booking-list .table-booking-inn td.discount-amount {
    font-size: 12px;
    color: #82a1b0;
    text-align: right;
}

.table-booking-list .table-booking-inn td.total {
    text-align: left;
    vertical-align: middle;
}

.table-booking-list .table-booking-inn td p img.class-icon {
    margin-top: -5px;
    margin-right: 6px;
}

.table-booking-list .table-booking-inn td:nth-child(2) {
    text-align: right;
    /* width: 120px; */
}

.table-booking-list .table-booking-inn td:nth-child(2) p {
    padding: 0;
}

.booking-date-table {
    font-weight: 500;
}

.booking-date-table td {
    padding: 3px 0;
}

.booking-date-table tbody tr:nth-child(2) td {
    border-bottom: 0;
}

.table-booking-list .booking-date-table td:nth-child(2) {
    border: 0 !important;
    border-bottom: 0 !important;
}

.table-booking-list .booking-date-table td:nth-child(3) {
    border: 0 !important;
}

.table-booking-list .booking-date-table td {
    font-size: 12px;
    font-weight: 500;
}

.table-booking-list .booking-date-table td.date-right {
    font-size: 12px;
    text-align: right;
    color: #335291;
    font-weight: normal;
}

.table-booking-list .booking-date-table td.date-right b {
    font-size: 12px;
}

.table-booking-list .booking-date-table td.cut-off {
    border-bottom: 0;
}

.quote-includes ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.quote-excludes ul {
    padding: 0;
    margin: 12px 0 0 0;
    list-style: none;
}

.quote-includes a {
    background: #e2fee8;
    padding: 6px 10px;
    color: #5da46b;
    display: inline-block;
    text-decoration: none;
    border-radius: 20px;
    font-size: 13px;
}

.quote-excludes a {
    background: #fff2f3;
    padding: 6px 10px;
    color: #cd6b75;
    display: inline-block;
    text-decoration: none;
    border-radius: 20px;
    font-size: 13px;
}

.quote-includes li i {
    color: #25b745;
    font-size: 13px;
}

.quote-excludes li i {
    color: #ef5563;
    font-size: 13px;
}

.quote-excludes li {
    font-size: 13px;
    display: inline-block;
    padding: 5px;
    color: #979696;
}

.quote-includes li {
    font-size: 13px;
}

.quote-includes {
    margin-top: 15px;
}

.quote-excludes {
    margin: 14px 0 24px 0;
}

.quote-includes ul {
    margin-top: 10px;
}

.cro-dive h5 {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    color: #335291;
}

.cro-dive-box h1 {
    background: #335291;
    color: #90ffb5;
    font-size: 13px;
    padding: 7px 15px;
    margin: 0;
    border-radius: 10px 10px 0 0;
    font-weight: normal;
}

.cro-dive-box h2 {
    text-transform: uppercase;
    font-size: 14px;
    color: #335291;
    letter-spacing: 1px;
    font-weight: bold;
    margin: 0 0 15px 0;
}

.cro-dive-box-details p {
    text-transform: uppercase;
    color: #000;
    margin-bottom: 0;
    font-size: 11px;
}

.cro-dive-box-details b {
    color: #179ad2;
    font-size: 13px;
    font-weight: 500;
}

.cro-dive ul.cro-dive-box-view-date {
    list-style: none;
    padding: 10px 0;
    margin: 0;
    display: flex;
    margin-top: 18px;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}

.cro-dive ul.cro-dive-box-view-date li {
    font-size: 12px;
    display: inline-block;
    padding: 0 24px;
    text-transform: uppercase;
    color: #6b7281;
    border-right: 1px solid #525967;
}

.cro-dive ul.cro-dive-box-view-date li b {
    margin-left: 8px;
    color: #525967;
}

.cro-dive ul.cro-dive-box-view-date li:last-child {
    padding-right: 0;
    border-right: 0;
}

.cro-dive ul.cro-dive-box-view-date li:first-child {
    padding-left: 0;
}

.documents h1 {
    text-transform: uppercase;
    font-size: 14px;
    color: #335291;
    letter-spacing: 1px;
    font-weight: bold;
    margin: 25px 0 15px 0;
}

.documents h6 {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 15px;
}

.documents ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.documents ul li {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
}

.documents ul li img {
    margin-right: 10px;
}

.documents ul a {
    list-style: none;
    text-decoration: none;
    border-radius: 20px;
    border: 1px solid #c6e2f3;
    padding: 5px 13px;
    color: #179ad2;
    font-size: 12px;
    display: inline-block;
    margin-bottom: 0;
}

.cro-dive-box-full {
    border-radius: 0 0 20px 20px;
    border: 1px solid #ddecf3;
    padding: 15px 15px 15px 15px;
    box-shadow: 4px 5px 11px -11px #000;
    margin-bottom: 20px;
    border-top: 0;
}

.cro-dive.vgm-div .cro-dive-box h1 {
    background: #faeaee;
    color: #c22849;
    font-size: 13px;
    padding: 10px;
    margin: 0;
    border-radius: 10px 10px 0 0;
    border: 1px solid #ddecf3;
    border-bottom: 0;
}

.vgm-div-box-right a {
    list-style: none;
    text-decoration: none;
    margin-bottom: 5px;
    margin-right: 10px;
}

.vgm-div-box-right li {
    display: inline-block;
    margin-right: 0;
    border-radius: 20px;
    border: 1px solid #c6e2f3;
    font-size: 12px;
    padding: 5px 14px;
    cursor: pointer;
    margin-bottom: 0;
}

.vgm-div-box-right li.border-vgm-next {
    border: none;
    padding: 0;
    margin-left: 8px;
}

.vgm-div-box-right li.border-vgm-next button {
    border-radius: 20px;
    border: 1px solid #c6e2f3;
    font-size: 13px;
    padding: 5px 20px;
    margin-bottom: 0;
    background: #335291;
    color: #fff;
    cursor: pointer;
}

.vgm-div-box-right li i {
    cursor: pointer;
}

.vgm-div-box-right li img {
    margin-right: 10px;
}

.cro-dive-box-full p {
    margin-bottom: 0;
}

.vgm-div-box-right ul {
    padding: 4px 0;
    margin: 0;
    list-style: none;
    text-align: right;
}

.vgm-div-box-left h2 {
    font-size: 14px;
    text-transform: uppercase;
    color: #335291;
    padding: 12px 0;
    margin-bottom: 0;
    font-weight: 400;
}

.vgm-div-box-right.upload-btn a {
    padding: 8px 95px;
    color: #335291;
    border: 1px solid #335291;
    font-weight: 500;
}

.vgm-div-box-right button {
    background: transparent;
    border: none;
    padding: 8px 15px;
}

.full-list-booking .confirm-booking-table {
    margin-top: 16px;
}

.cro-dive.shipping-bill .cro-dive-box h1 {
    background: #fcf1eb;
    color: #d7723b;
    font-size: 13px;
    padding: 10px;
    margin: 0;
    border-radius: 10px 10px 0 0;
    font-weight: 400;
}

.rounded h1 {
    border-radius: 10px !important;
}

.cro-dive.shipping-bill .vgm-div-box-right ul {
    text-align: left;
}

.cro-dive.shipping-bill .vgm-div-box-right i {
    text-align: right;
    /*margin-left: 10px;*/
    font-size: 15px;
    vertical-align: middle;
    color: #335291;
}

.white {
    color: white !important;
}

.cro-dive.shipping-bill .vgm-div-box-right button {
    padding: 7px 60px;
    color: #335291;
    border: 1px solid #335291;
    font-weight: 500;
    list-style: none;
    text-decoration: none;
    border-radius: 5px;
    background: #fff;
    font-size: 13px;
    margin-top: 0;
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cro-dive.shipping-bill .vgm-div-box-right button span {
    font-size: 10px;
}

.upload-2-files p {
    margin-top: 8px;
    margin-bottom: 0;
}

.full-list-booking .bookin-gquote-heading hr {
    border-top: 2px solid #e7f6fd;
    margin-top: 50px;
}

td.class-select b {
    font-size: 16px;
}

.all-box.active-booking {
    border: 1px solid #335292;
    border-radius: 10px;
}

.no-booking {
    background: #e7f6fd;
    min-height: 250px;
    text-align: center;
    padding-top: 100px;
    display: block;
    font-size: 28px;
    color: #335291;
}

.booking-name .port-name {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.small-alert {
    font-size: 13px;
    padding: 0.25rem 1.25rem;
}

.small-alert .close {
    padding: 0.2rem 1.25rem;
    font-size: 23px;
}

.upload-2-files {
    text-align: center;
    margin: 0 auto;
}

.upload-2-files .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    /* display: inline-block; */
    width: 100%;
}

.upload-2-files .btn {
    color: #fff;
    background-color: #2ecf7a;
    padding: 10px 20px;
    font-size: 13px;
    margin-right: 0;
    border-radius: 30px;
    text-transform: uppercase;
    border-bottom: 2px solid #cecece;
}

.upload-2-files .upload-btn-wrapper input[type=file] {
    font-size: 22px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 120px;
}
.upload-2-files .upload-btn-wrapper.comp_fileupld input[type=file]{
    top: 30px;
}

.cro-dive.shipping-bill .vgm-div-box-right button.upload-button {
    margin-top: 0;
    margin-left: 0;
    vertical-align: middle;
    background: #335291;
    color: #fff;
    border-radius: 20px;
    padding: 7px 36px;
}


/* React-Calendar changes */

.react-calendar__tile--active {
    background: #00c3a9;
    color: white !important;
    border-radius: 5px;
}

.react-calendar__month-view__days__day--weekend {
    color: #00c5a9;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #00b497;
    color: white;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 1em 0.5em;
}

.boat-wave-in section {
    width: 100%;
    overflow: hidden;
    position: absolute;
}

.boat-wave-in ul.no-bullet {
    list-style: none;
    padding: 0;
    margin: 0;
}

.boat-wave-in .main {
    height: 21%;
    bottom: 0;
    z-index: -1;
}

.boat-wave-in .absolute {
    position: absolute;
}

.boat-wave-in .plane {
    top: 9%;
    left: 80px;
    -webkit-animation: planeanimate 70s linear infinite;
    -moz-animation: planeanimate 70s linear infinite;
    -o-animation: planeanimate 70s linear infinite;
    animation: planeanimate 70s linear infinite;
}

@-webkit-keyframes planeanimate {
    0% {
        left: -20%;
    }
    100% {
        left: 110%;
    }
}

@-moz-keyframes planeanimate {
    0% {
        left: -30%;
    }
    100% {
        left: 110%;
    }
}

@-o-keyframes planeanimate {
    0% {
        left: -20%;
    }
    100% {
        left: 110%;
    }
}

@keyframes planeanimate {
    0% {
        left: -20%;
    }
    100% {
        left: 110%;
    }
}

.boat-wave-in .plane-body {
    width: 285px;
    height: 97px;
    background-image: url("../../assets/images/boat2.png");
}

.boat-wave-in .plane-body {
    animation: boatwave 6s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.full-screen-modal.modal.show .modal-dialog {
    max-width: 99%;
    height: 100vh;
    border-radius: 0;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.full-screen-modal.modal.fade.show .modal-dialog {
    top: 0;
}

.full-screen-modal .modal-content {
    height: 100vh;
    border: none;
}

.full-screen-modal.modal.fade .modal-dialog {
    transition: -webkit-transform 0.5s, bottom 0.5s ease-in-out;
    transition: transform 0.5s, bottom 0.5s ease-in-out;
    transition: transform 0.5s, bottom 0.5s ease-in-out, -webkit-transform 0.5s, bottom 0.5s ease-in-out;
    -webkit-transform: translate(0, -1500px) !important;
    transform: translate(0, -1500px) !important;
}

.full-screen-modal.modal.show .modal-dialog {
    -webkit-transform: none !important;
    transform: none !important;
}

.full-screen-modal.fade {
    transition: none;
    background: transparent;
}

.modal-backdrop.show {
    opacity: 0.9;
    display: none;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: inherit;
}

.full-screen-modal .modal-body {
    overflow: auto;
    overflow-x: hidden;
    padding: 0;
    border: 1px solid #bacffb;
    border-radius: 0;
    border-top: none;
    margin-bottom: 7px;
}

.static-data-input label {
    font-size: 14px;
    color: #335291;
    width: 100%;
    font-weight: 500;
}

.static-data-input p {
    font-weight: 400;
    color: #000;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 0;
}

.static-data-input {
    margin-bottom: 3px;
}

/* .div-static-data-full {
    border-bottom: 2px solid #e7f6fd;
    margin-bottom: 20px;
} */

.static-data-input select.form-control:not([size]):not([multiple]) {
    height: auto;
}

.static-data-input .form-group {
    margin-bottom: 0;
}

.static-data-input .form-control {
    padding: 0;
    background-color: #fff;
    border: 0 solid #ced4da;
    border-radius: 0;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    color: #000;
}

.pop-data-type-container label {
    font-size: 14px;
    color: #335291;
    width: 100%;
    font-weight: 400;
    margin-bottom: 0;
    text-transform: capitalize;
}

.pop-data-type-container label.custom-file-label {
    color: #000;
}

.pop-data-type-container label.custom-control-label {
    color: #000;
}

.pop-data-type-container label.d-block {
    font-weight: normal;
}

.pop-data-type-container .form-control {
    padding: 3px;
    font-size: 14px;
    color: #000;
    border: 1px solid #c6e2f3;
    font-weight: 400;
    border-radius: 0;
}

.pop-data-type-container .form-group {
    margin-bottom: 0;
}

.full-list-booking .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    text-align: center;
    width: 100%;
}

.pop-data-type-container .react-datepicker-wrapper input {
    width: 100%;
    padding: 3px;
    font-size: 14px;
    border-radius: 3px;
    color: #000;
    font-weight: 400;
    border: 1px solid #c6e2f3;
}

.pop-data-type-container .react-datepicker-wrapper {
    display: inherit;
}

.pop-data-type-container .custom-control {
    display: inline-block;
    margin-right: 16px;
}

.pop-data-type-container label.alert-wr {
    display: none;
    font-size: 12px;
}

.alert-wr {
    display: none;
    font-size: 12px;
}

.alert-wr-bk {
    display: block;
    font-size: 12px;
}

.pop-data-type-container select.form-control:not([size]):not([multiple]) {
    height: auto;
}

.pop-data-type-container .form-edit-text {
    margin-bottom: 3px;
}

.full-screen-modal .modal-title {
    /*text-align: center;*/
    width: 100%;
    /*text-transform: uppercase;*/
    color: #335291;
    font-size: 15px !important;
}

.full-screen-modal .modal-header {
    padding: 3px 23px;
    border: 1px solid #bacffb;
    border-radius: 0;
    background: #e9f4ff;
    margin-top: 7px;
}

.div-static-data-full.vgmview-list,
.vgmviewlist {
    padding: 20px;
}

.div-static-data-full.vgmview-list {
    margin-bottom: 0;
}

.pop-data-type-container .custom-file-label::after {
    padding: 8px 15px;
    cursor: pointer;
    background-color: #53a7fb;
    color: #fff;
}

.pop-data-type-container .custom-file-input:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.vgm_sub button {
    border-radius: 60px;
    border: 1px solid #c6e2f3;
    font-size: 15px;
    padding: 10px 80px;
    margin-bottom: 10px;
    background: #335291;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
}

.vgm_sub button:hover {
    background: #547fd6;
}

a.view-upload-file {
    display: inline-block;
    border: 1px solid #c6e2f3;
    padding: 10px 35px;
    vertical-align: middle;
    margin-top: 0;
    border-radius: 25px;
    font-size: 13px;
    margin-left: 14px;
    color: #007bff;
    text-decoration: none;
}

.add-line-bg h5 {
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 0;
}

.add-line-bg a.view-upload-file {
    display: inline-block;
    border: 1px solid #c6e2f3;
    padding: 10px 35px;
    vertical-align: middle;
    margin-top: 0;
    border-radius: 25px;
    font-size: 13px;
    margin-left: 14px;
    color: #fff;
    text-decoration: none;
}

.add-line-bg a.view-upload-file:hover {
    border: 1px solid #fff;
}

a.view-upload-file:hover {
    border: 1px solid #007bff;
}

.pop-data-type-container .custom-file {
    width: auto;
}

.modal-data-input input {
    font-size: 14px;
}

.modal-data-input label {
    font-size: 13px;
    color: #777;
}

.modal-data-input textarea {
    resize: none;
    font-size: 14px;
}

.modal-data-input select {
    height: inherit !important;
}

.full-screen-modal .table {
    margin: 0 1rem 1rem 1rem
}

.full-screen-modal thead {
    color: #777777;
}

.full-screen-modal .table .form-group {
    margin-bottom: 0;
}

.bl-view-all {
    padding: 20px;
}

.bl-view-all .static-data-input b {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
}

.bl-view-all .static-data-input p {
    text-transform: inherit;
    font-size: 13px;
    font-weight: 500;
}

.bl-view-all h1 {
    font-size: 14px;
    text-transform: uppercase;
    margin: 0;
}

.bl-view-all .heading-popup {
    color: #335292;
    background: #e7f6fd;
    padding: 10px 10px 7px 10px;
    margin-bottom: 10px;
}

.party-and-vessel-details {
    border-bottom: 2px solid #e7f6fd;
    margin-bottom: 20px;
}

.option-in .react-datepicker__input-container input {
    width: 100%;
    border-left: 0;
    border-bottom: 1px solid #ccc;
    border-top: 0;
    border-right: 0;
    padding: 6px;
}

.option-in .react-datepicker-wrapper {
    width: 100%;
}

.date-change .react-datepicker__input-container input.modify-datepicker {
    width: 100%;
    border-left: 0;
    border-bottom: 1px solid transparent;
    border-top: 0;
    border-right: 0;
    padding: 9px 0;
    font-size: 13px;
    color: #6c7993;
    background: transparent;
}

.date-change .react-datepicker__input-container input.search-datepicker {
    width: 100%;
    border-left: 0;
    border-bottom: 1px solid #ccc;
    border-top: 0;
    border-right: 0;
    padding: 9px 0;
    font-size: 13px;
    font-weight: 500;
}

.date-change .react-datepicker-wrapper {
    width: 100%;
}

.shipment-details-table-inn .table {
    width: 100%;
    margin: 0;
    margin-bottom: 21px;
}

.shipment-details-table-inn .table thead th {
    border: 1px solid #dee2e6;
    font-weight: 500;
    color: #000;
    font-size: 13px;
    background: #f5faff;
}

.shipment-details-table-inn .table tbody td {
    border: 1px solid #dee2e6;
    font-size: 14px;
}

.shipment-details-table-inn {
    border-bottom: 2px solid #e7f6fd;
    margin-bottom: 20px;
}

.shipment-details-table tr.total-amount-fix {
    font-weight: 500;
}

.full-screen-modal .table th {
    font-weight: 500;
}

.total-row {
    white-space: nowrap;
    font-weight: 500;
}

.full-screen-modal .form-control:disabled,
.form-control[readonly] {
    background-color: #f1f1f157;
    border: 1px solid #e8e8e8;
}

textarea::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    border-radius: 5px;
}

.bl_sub {
    width: 100%;
    display: flex;
    justify-content: center;
}

.bl_sub button {
    margin: 10px 0;
    font-size: 13px;
    border-radius: 60px;
    border: 1px solid #c6e2f3;
    background: #335291;
    color: #fff;
    cursor: pointer;
}

.modal {
    background: rgba(0, 0, 0, 0.9);
}

td.goods_description-one-full {
    width: 100%;
}

td.goods_description-one {
    width: auto !important;
    padding: 0;
}

td.goods_description {
    width: 80% !important;
    padding: 0;
    text-align: left !important;
}

.changed-input {
    border-color: #C44DC0 !important;
    box-shadow: 0 0 0 0.2rem rgba(196, 77, 192, 0.5) !important;
}

.footer-last {
    padding: 3px 10px;
    background: #e6ebf6;
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 0;
}

.footer-last ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline-block;
    margin-left: auto;
    float: right;
}

.footer-last p {
    font-size: 13px;
    color: #4c5975;
    display: inline-block;
    margin: 0;
}

.footer-last ul li {
    display: inline-block;
}

.footer-last ul li a {
    text-decoration: none;
    color: #4c5975;
    font-size: 13px;
    padding: 0 10px;
    border-right: 1px solid #4c5975;
    cursor: context-menu;
}

.footer-last ul li:last-child a {
    border-right: none;
}

.reset-password {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}

.reset-password.form-model {
    height: auto;
    min-height: auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 13px 12px 6px -8px #ddd;
}

.search-btn-Reset button {
    background: #335291;
    color: #fff;
    padding: 10px 45px;
    border-radius: 20px;
    margin: 20px auto 0;
    text-align: center;
    border: 0;
    cursor: pointer;
}

.search-btn-Reset {
    text-align: center;
}

.reset-password.form-model h1 {
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 10px;
}

.reset-password.form-model h1 span {
    font-size: 30px;
}

.search-btn-Reset button:hover {
    background: #245bca;
}

.reset-password.form-model .input__field--nao {
    padding: 20px 0 8px 0;
}

.search-input-file-cng .custom-control {
    display: inline-block;
    margin: 6px 15px 6px 0;
}

.search-input-file-cng input {
    width: 90%;
    padding: 6px 17px;
    border: 1px solid #ddd;
    border-right: 0;
    border-radius: 16px 0 0 16px;
    margin-bottom: 10px;
    font-size: 14px;
}

.search-input-file-cng button {
    background: #fff;
    padding: 6px 12px;
    border: 1px solid #ddd;
    border-left: 0;
    border-radius: 0 16px 16px 0;
    font-size: 14px;
}

.search-input-file-cng button i {
    color: #375593;
}

.search-input-file-cng {
    margin-bottom: 20px;
}

.search-input-file-cng .material-control-description {
    font-size: 12px;
}

.search-input-file-cng .css-12jo7m5 {
    color: #335390;
    font-size: 12px;
    padding: 2px;
    padding-left: 8px;
}

.search-input-file-cng .css-1rhbuit-multiValue {
    background-color: #e2e9f3;
    border-radius: 30px;
    margin: 1px;
}

.search-input-file-cng .css-xb97g8:hover {
    background-color: transparent;
    color: #5069a2;
}

.search-input-file-cng .css-xb97g8 {
    background-color: transparent;
    color: #8ca0d0;
}

.search-input-file-cng .css-tlfecz-indicatorContainer {
    padding: 0 4px;
}

.search-input-file-cng .css-1hwfws3 {
    padding: 2px;
    font-size: 14px;
}

.search-input-file-cng .basic-multi-select input {
    margin-bottom: 0;
}

.search-input-file-cng .css-1pahdxg-control:hover {
    margin-bottom: 0;
    box-shadow: none;
}

.search-input-file-cng .css-1pahdxg-control {
    margin-bottom: 0;
    box-shadow: none;
    padding: 0 10px;
    border-radius: 0;
    min-height: auto;
}

.search-input-file-cng .css-1gtu0rj-indicatorContainer {
    padding: 0 4px;
}

.search-input-file-cng .css-yk16xz-control {
    border-radius: 16px;
    padding: 0 10px;
    min-height: auto;
}

.search span.span-select {
    display: inline-block;
    width: 100%;
    font-size: 13px;
    padding: 9px 1px 8px 0;
    min-height: 60px;
    font-weight: 500;
    color: #6c7993;
}

img.haz {
    width: 16px;
}

img.pending-icon {
    width: 12px;
    margin-top: -5px;
}

.dry-reefer-select ul {
    margin: 10px auto 0;
    text-align: center;
    list-style: none;
    padding: 0;
    background: #fff;
    /* border: 1px solid #ddd; */
    width: auto;
    box-shadow: 4px 3px 11px -4px #ddd;
    border-radius: 30px;
    display: inline-block;
    border: 1px solid #ddd;
}

.dry-reefer-select ul li {
    display: inline-block;
    margin: 0 auto;
    text-align: center;
    width: auto;
    float: none;
    border: none;
    padding: 0;
}

.dry-reefer-select ul li a {
    padding: 5px 17px;
    display: inline-block;
    border-radius: 30px;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: normal;
    color: #808080;
    text-decoration: none;
    letter-spacing: 1px;
}

.dry-reefer-select ul li a.active.box-chng {
    color: #fff;
    background: #335291;
    border-radius: 15px 0 0 15px;
    border: 1px solid #335291;
}

.dry-reefer-select ul li a.active {
    color: #fff;
    background: #335291;
    border-radius: 0;
    border: 1px solid #335291;
}

.dry-reefer-select ul li a.active.box-chng-snd {
    color: #fff;
    background: #335291;
    border-radius: 0 15px 15px 0;
    border: 1px solid #335291;
}

.dry-reefer-select {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    display: inline-block;
}

.box-count ul li.hidedive {
    position: relative;
}

.hidedive-box {
    position: absolute;
    background: rgba(231, 246, 253, 0.9);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    right: auto;
}

.option-in .react-datepicker__day-name,
.option-in .react-datepicker__day,
.option-in .react-datepicker__time-name {
    width: 25px;
    line-height: 20px;
    margin: 2px;
    font-size: 11px;
}

.option-in .react-datepicker__current-month {
    font-size: 13px;
}

.option-in .react-datepicker__header {
    padding-top: 5px;
}

.option-in .react-datepicker__month {
    margin: 3px;
}

.option-in .react-datepicker__navigation--previous {
    border-right-color: #0a0a0a;
}

.option-in .react-datepicker__navigation--next {
    border-left-color: #0a0a0a;
}

.cro-dive-box-details.new-mt {
    margin-top: 20px;
}

.cro-dive-box-details.new-mt b {
    font-weight: normal;
}

p.port-name-chng {
    font-weight: 500;
    margin-bottom: 0;
}

.bookoing-right-table {
    padding: 0 10px !important;
}

.confirm-booking-table td {
    padding: 4px !important;
}

td.total b {
    font-weight: 500;
    font-size: 14px;
}

.new-table-td {
    /* border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd; */
}

p.class-new-p {
    text-align: right !important;
}

td.class-select {
    border-bottom: 1px solid #ddd;
}

td.new-table-td {
    /* border-bottom: 1px solid #ddd !important; */
}

.heading-enquiry h1 {
    font-size: 18px;
    background: #335291;
    color: #a9c6ff;
    padding: 8px 19px;
    font-weight: 400;
    text-transform: uppercase;
    border-radius: 30px;
}

.enquiry-form-page {
    margin-top: 0;
}

.heading-enquiry p {
    font-size: 16px;
    margin-top: 21px;
    color: #33445f;
    margin-bottom: 20px;
    font-weight: normal;
    border-bottom: 1px solid #e2ecff;
    padding-bottom: 21px;
}

.input-box-div-inn label {
    width: 100%;
    font-size: 14px;
}

.input-box-div-inn input {
    width: 100%;
    font-size: 14px;
    padding: 5px 10px;
    border: 1px solid #ddd;
    color: #3e3e3e;
}

.input-box-div-inn {
    margin-bottom: 27px;
}

.input-box-div-inn select {
    width: 100%;
    font-size: 14px;
    padding: 6px 10px;
    border: 1px solid #ddd;
    color: #3e3e3e;
}

.input-box-div-inn textarea {
    width: 100%;
    border: 1px solid #ddd;
    color: #3e3e3e;
    font-size: 14px;
    padding: 6px 10px;
}

.input-box-div-inn-btn button {
    background: #335291;
    color: #fff;
    padding: 10px 22px;
    border-radius: 30px;
    border: 0;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: 1px;
}

.input-box-div-inn-btn button:hover {
    background: #1a48a5;
}

.input-box-div-inn-btn {
    text-align: right;
    margin-top: 17px;
}

.input-box-div-inn-btn button {
    font-size: 12px;
    padding: 8px 27px;
    margin-left: 10px;
}

.input-box-div-inn-btn button:nth-child(1) {
    margin-left: 0;
}

.input-box-div-inn-btn button:nth-child(2) {
    background: #58a9bb;
}

.popover-header {
    font-size: 13px;
    padding: 4px;
    text-align: center;
    color: #000;
}


/* .form-modelnew {
    background: #fff;
    border-radius: 20px;
    border: 1px solid #ddd;
    padding: 20px;
    position: relative;
} */

.form-modelnew h5 {
    font-size: 16px;
    font-weight: normal;
    text-align: center;
}

.sign-up-formnew {
    background: #fff;
    border-radius: 0;
    border: 0 solid #ddd;
    padding: 20px !important;
    position: relative;
}

.pop-up-loginnew.modal-dialog {
    max-width: 65%;
}

.form-modelnew-inn input {
    width: 100%;
    border: 1px solid #ddd;
    padding: 8px;
}

.form-modelnew-inn input.from-in {
    width: 100%;
    border: 1px solid #ddd;
    padding: 8px;
}

.form-modelnew {
    margin-bottom: 17px;
}

.table-div {
    margin-top: 0;
    margin-bottom: 17px;
}

.table-div table {
    width: 100%;
    border: 1px solid #ddd;
}

.table-div table th {
    font-weight: normal;
    font-size: 13px;
    border: 1px solid #b1b1b1;
    padding: 5px;
    background: #ddd;
}

.table-div table th input {
    width: 100%;
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 12px;
}

.table-div table td {
    font-weight: normal;
    font-size: 14px;
    border: 1px solid #ddd;
    padding: 5px;
}

.table-div table td input {
    width: 100%;
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 12px;
}

.form-modelnew-inn {
    font-size: 13px;
    margin-bottom: 20px;
}

.pop-up-loginnew h5 {
    text-align: center;
    width: 100%;
}

.form-modelnew h5 {
    font-family: 'Rubik', sans-serif !important;
    line-height: 25px;
}

.pop-up-loginnew .modal-header {
    padding: 5px;
    background: #d5eeff;
}

.btn-table-last {
    padding: 0;
    margin: 20px 0 0 0;
    list-style: none;
    text-align: center;
}

.table-div select {
    width: 100%;
    border: 1px solid #ddd;
    padding: 9px 5px;
    font-size: 12px;
    color: #767676;
}

.table-div select {
    -moz-appearance: checkbox;
}

.table-div table td span {
    font-weight: normal;
    font-size: 18px;
    border: 1px solid #ddd;
    color: #000;
    background: #ddd;
    border-radius: 50%;
    padding: 0 6px;
}

.table-div table td a {
    text-decoration: none;
}

.form-modelnew-inn label {
    width: 100%;
}

.form-modelnew-inn textarea {
    width: 100%;
    border: 1px solid #ddd;
    padding: 4px 8px;
}

.div-popup-all {
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 463px;
    height: 463px;
    padding-right: 5px;
}

.menu-full .signup-btn span {
    font-weight: normal;
    padding: 0 5px;
}

.menu-full .signup-btn i {
    vertical-align: inherit;
}

.menu-full .signup-btn i.i-new {
    margin-top: -10px;
}

.menu-full .signup-btn.btn-outline-success:not(:disabled):not(.disabled):active {
    background-image: linear-gradient(141deg, #00afb6 0%, #00bbaf 51%, #00c5a9 75%);
}

.bl-notice {
    float: right;
    margin: 5px;
    font-size: 14px;
}


/***********************confirm-booking-new-UI-START***************************/

.new-change-confirm-booking-full {
    margin-top: 20px;
}

.new-change-confirm-booking {
    border: 1px solid #335291;
    background: #f2f5f6;
    border-radius: 15px;
    padding: 20px 15px;
}

.center-div-col {
    margin: 0 auto;
}

.center-div-border {
    border-right: 1px solid #c4d2d7
}

.confirm-booking-inn-box h5 {
    text-transform: uppercase;
    font-size: 11px;
    color: #000;
}

.confirm-booking-inn-box span {
    color: #335291;
    text-transform: uppercase;
    font-size: 11px;
}

.confirm-booking-inn-box h1 {
    font-size: 18px;
    font-weight: 500;
    color: #335291;
    min-height: 58px;
    margin-bottom: 0;
}

.confirm-booking-inn-box b {
    font-size: 12px;
    color: #7685a3;
    font-weight: 500;
    width: 100%;
    display: inline-block;
}

.confirm-booking-inn-box b span {
    font-weight: normal;
    text-transform: uppercase;
    font-size: 11px;
    color: #7685a3;
    margin-right: 6px;
}

.confirm-booking-inn-box-img p {
    color: #179ad2;
    font-size: 12px;
    font-weight: 500;
}

.confirm-booking-inn-box-img {
    margin: 0 auto;
    text-align: center;
    padding: 37px 0;
}

.div-p-no {
    padding: 0;
}

.total-charges-newconfirm-box h1 {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    margin-bottom: 12px;
}

.total-charges-newconfirm-box p {
    font-size: 11px;
    color: #000;
    font-weight: 500;
    margin-bottom: 0;
}

.total-charges-newconfirm-box b {
    font-size: 18px;
    color: #22c55f;
}

.total-charges-newconfirm-box b span {
    font-size: 12px;
    color: #22c55f;
}

.total-charges-newconfirm-box ul {
    list-style: none;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #c4d2d7;
    padding-bottom: 13px;
    margin-bottom: 13px;
    text-align: center;
}

.total-charges-newconfirm-box li {
    display: inline-block;
}

.total-charges-newconfirm-box li:nth-child(2) {
    padding: 14px 20px 0 20px;
}

.total-charges-newconfirm-box li:nth-child(3) b {
    color: #21b0a1;
}

.total-charges-newconfirm-box li:nth-child(3) b span {
    color: #21b0a1;
}

.view-price-breakdown-newconfirm-box h2 {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

.view-price-breakdown-newconfirm-box h2 span {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: normal;
    color: #000;
    width: auto;
}

.view-price-breakdown-newconfirm-box span {
    color: #c35d5d;
    font-size: 11px;
    text-align: center;
    width: 100%;
    display: inline-block;
    font-weight: 500;
}

.view-price-breakdown-newconfirm-box button {
    background: #fff;
    border: 1px solid #fff;
    color: #335291;
    border-radius: 30px;
    padding: 8px 25px;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    margin: 10px auto 0;
    box-shadow: 2px 6px 8px 4px #ddd;
    cursor: pointer;
}

.view-price-breakdown-newconfirm-box button:hover {
    box-shadow: none;
    border: 1px solid #ddd;
}

.view-price-breakdown-newconfirm-box {
    text-align: center;
    margin: 0 auto;
}

.new-box-port p {
    margin-bottom: 0;
    display: inline-block;
    color: #51666f;
    font-size: 12px;
}

.new-box-port span {
    display: inline-block;
    margin-right: 15px;
    width: 36px;
    height: 36px;
}

.new-box-port {
    margin-top: 25px;
}

.confirm-booking-new-table table {
    width: 100%;
}

.confirm-booking-new-table table th {
    background: #335291;
    color: #fff;
    font-size: 11px;
    padding: 5px 8px;
    font-weight: normal;
    text-transform: uppercase;
}

.confirm-booking-new-table table th:nth-child(1) {
    width: 65%;
    border-radius: 10px 0 0 10px;
}

.confirm-booking-new-table table th:nth-child(2) {
    text-align: center;
}

.confirm-booking-new-table table th:nth-child(3) {
    text-align: right;
    border-radius: 0 10px 10px 0;
}

.confirm-booking-new-table table td:nth-child(2) {
    text-align: center;
}

.confirm-booking-new-table table td:nth-child(1) {
    font-weight: 600;
}

.confirm-booking-new-table table td {
    padding: 6px 8px;
}

.confirm-booking-new-table table td:nth-child(3) {
    text-align: right;
}

.confirm-booking-new-table table td:nth-child(3) input {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    border-bottom: 1px solid #959aa4;
    background: transparent !important;
    text-align: right;
    width: 70%;
    font-weight: 600;
}

.confirm-booking-new-table table td:nth-child(3) input::placeholder {
    font-weight: 400;
    color: #b1b1b1;
}

.confirm-booking-new-table .counter-bg {
    background-color: #ffffff;
    display: inline-block;
    border-radius: 10px;
    padding: 0;
    margin-left: 0;
}

.new-class-tr-div-packing-group .form-control {
    background: transparent;
}

.confirm-booking-new-table span {
    font-size: 12px;
    color: #335291;
    text-transform: uppercase;
    margin: 0 10px 0 8px;
}

.confirm-booking-new-table .counter-bg input {
    background-color: transparent;
    border: 0 solid #ced4da;
    text-align: center;
    width: 26px !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    display: inline-block;
    font-size: 17px;
    padding: 0;
    color: #000;
    font-weight: normal;
    height: auto;
}

.confirm-booking-new-table button {
    padding: 0;
    background: transparent;
    box-shadow: none;
}

.confirm-booking-new-table .counter-bg i {
    font-size: 12px;
    vertical-align: middle;
    margin-top: -4px;
    color: #6dcef0;
}

.confirm-booking-new-table {
    margin-top: 10px;
    border-bottom: 1px solid #c4d2d7;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.new-class-tr-div-packing-group.btnfull-freight-terms select {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    font-size: 13px;
    color: #000;
    padding: 0;
}

.price-breackdown-popup.add-exporter button.add-exporter-btn {
    border: none;
    background: #335291;
    color: #fff;
    padding: 8px 20px;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: 2px 6px 8px 4px #ddd;
    font-size: 14px;
    margin-top: 23px;
}

.new-class-tr-div-packing-group.btnfull-freight-terms .form-group {
    width: 87%;
    display: inline-block;
}

.new-class-tr-div-packing-group.btnfull-freight-terms .btn {
    border-radius: 50%;
    padding: 3px 0;
    background: transparent;
    border: 1px solid #335291;
    margin-left: 9px;
}

.new-change-confirm-booking .form-control:focus {
    background: transparent;
}

.new-class-tr-div-packing-group.btnfull-freight-terms .btn i {
    font-size: 15px;
    line-height: 0;
    padding: 0 10px;
    cursor: pointer;
}

.new-cargo-contains-hazardous .container-lable {
    display: block;
    position: relative;
    padding-left: 0px;
    margin-bottom: 0;
    cursor: pointer;
    padding-top: 2px;
    font-size: 14px;
    -webkit-user-select: none;
    user-select: none;
    color: #000;
    font-weight: 600;
}


/* Hide the browser's default checkbox */

.new-cargo-contains-hazardous .container-lable input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.new-cargo-contains-hazardous .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #dee2e6;
    border-radius: 5px;
}


/* On mouse-over, add a grey background color */

.new-cargo-contains-hazardous .container-lable:hover input ~ .checkmark {
    background-color: #ccc;
}


/* When the checkbox is checked, add a blue background */

.new-cargo-contains-hazardous .container-lable input:checked ~ .checkmark {
    background-color: #6dcef0;
}


/* Create the checkmark/indicator (hidden when not checked) */

.new-cargo-contains-hazardous .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.new-cargo-contains-hazardous .container-lable input:checked ~ .checkmark:after {
    display: block;
}


/* Style the checkmark/indicator */

.new-cargo-contains-hazardous .container-lable .checkmark:after {
    left: 8px;
    top: 3px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}

.new-cargo-contains-hazardous {
    margin-top: 20px;
}

.new-class-tr-div p {
    font-size: 12px;
    color: #335291;
    font-weight: 500;
}

.new-class-tr-div-packing-group p {
    font-size: 12px;
    color: #335291;
    font-weight: 500;
}

.new-un-number p {
    font-size: 12px;
    color: #335291;
    font-weight: 500;
    text-align: right;
}

.new-class-tr-div-packing-group {
    margin-top: 15px
}

.new-class-tr-div {
    margin-top: 15px
}

.new-un-number {
    margin-top: 15px;
    text-align: right;
}

.new-class-tr-div-packing-group .container-lable {
    display: block;
    position: relative;
    padding-left: 29px;
    margin-bottom: 0;
    cursor: pointer;
    padding-top: 2px;
    font-size: 14px;
    -webkit-user-select: none;
    user-select: none;
    color: #8195bd;
    font-weight: 500;
}


/* Hide the browser's default checkbox */

.new-class-tr-div-packing-group .container-lable input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.new-class-tr-div-packing-group .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid #8195bd;
}


/* On mouse-over, add a grey background color */

.new-class-tr-div-packing-group .container-lable:hover input ~ .checkmark {
    background-color: transparent;
}


/* When the checkbox is checked, add a blue background */

.new-class-tr-div-packing-group .container-lable input:checked ~ .checkmark {
    background-color: #335291;
}


/* Create the checkmark/indicator (hidden when not checked) */

.new-class-tr-div-packing-group .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.new-class-tr-div-packing-group .container-lable input:checked ~ .checkmark:after {
    display: block;
}


/* Style the checkmark/indicator */

.new-class-tr-div-packing-group .container-lable .checkmark:after {
    left: 6px;
    top: 6px;
    width: auto;
    height: auto;
    border: solid white;
    border-width: 3px;
    border-radius: 50%;
    transform: rotate(45deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(45deg);
}

.new-class-tr-div-packing-group ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.new-class-tr-div-packing-group ul li {
    display: inline-block;
    margin-left: 16px;
}

.new-class-tr-div-packing-group ul li:nth-child(1) {
    margin-left: 0;
}

.new-class-tr-div-packing-group .custom-control-label {
    color: #8195bd;
    font-size: 14px;
}

.new-class-tr-div-packing-group .custom-control-inline {
    margin-right: 13px;
    padding-left: 19px;
}

.new-class-tr-div-packing-group .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0 #fff, 0 0 0 0 rgba(0, 123, 255, 00);
}

.new-un-number input {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    border-bottom: 1px solid #959aa4;
    background: transparent;
    text-align: right;
    width: 60%;
    font-weight: 500;
    font-size: 14px;
    padding: 0;
}

.cargo-line-hazardous-btn {
    border-bottom: 1px solid #c4d2d7;
    padding-bottom: 25px;
    margin-bottom: 22px;
}

.new-cargo-reefer-commodity {
    border-bottom: 1px solid #c4d2d7;
    padding-bottom: 25px;
    margin-bottom: 22px;
}

.commodity-box-one {
    margin-top: 15px
}

.commodity-box-one p {
    font-size: 12px;
    color: #335291;
    font-weight: 500;
}

.commodity-box-one input {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    border-bottom: 1px solid #959aa4;
    background: transparent;
    width: 45%;
    font-weight: 400;
    color: #b1b1b1;
    padding: 0;
}

.commodity-box-one .form-control {
    display: initial;
}

.commodity-box-one.align-change-ventilation {
    text-align: right;
}

.commodity-box-one.align-change-ventilation input {
    text-align: right;
}

.heading-commodity h5 {
    font-size: 14px;
    color: #000;
}

.new-describe-your-box label {
    font-size: 14px;
    color: #000;
    font-weight: 500;
}

.new-describe-your-box label span {
    font-weight: normal;
    font-size: 15px;
}

.new-describe-your-box textarea {
    width: 100%;
    border: 0;
    background: #fff;
    border-radius: 10px;
    padding: 10px;
}

.note-approvals {
    margin-top: 15px;
}

.note-approvals b {
    font-size: 14px;
    margin-bottom: 10px;
    display: inline-block;
    width: 100%;
}

.note-approvals b span {
    color: red;
}

.note-approvals p {
    font-size: 14px;
    color: #f03232;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    font-weight: 500;
}

.new-describe-your-cargo-full {
    border-bottom: 1px solid #c4d2d7;
    padding-bottom: 25px;
    margin-bottom: 22px;
}

.btn-confirm-new {
    margin: 0 auto;
    text-align: center;
    padding-bottom: 20px;
}

.btn-confirm-new ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.btn-confirm-new ul li {
    display: inline-block;
    margin: 0 15px;
}

.btn-confirm-new ul li:nth-child(2) button {
    background: #335291;
}

.btn-confirm-new ul li:nth-child(2) button:hover {
    background: #14419e;
}

.btn-confirm-new ul li button {
    border: none;
    background: #6dcef0;
    color: #fff;
    padding: 13px 40px;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: 2px 6px 8px 4px #ddd;
    font-size: 18px;
}

.btn-confirm-new ul li button:hover {
    background: #58bbde;
}

.btn-confirm-new p {
    font-size: 12px;
    color: #f03232;
    font-weight: 500;
    margin-bottom: 25px;
}

.new-class-tr-div-packing-group.btnfull-freight-terms {
    margin-top: 5px;
}

.new-un-number p img {
    margin-right: 5px;
    margin-top: -6px;
}

.view-price-breakdown-newconfirm-box span img {
    margin-left: 5px;
    margin-top: -6px;
}

.exporter-box P {
    font-size: 12px;
    color: #335291;
    font-weight: 500;
}

.exporter-box input {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    border-bottom: 1px solid #959aa4;
    background: transparent;
    width: 80%;
    font-weight: 400;
    color: #b1b1b1;
}

.exporter-box span {
    height: 25px;
    width: 25px;
    border: 1px solid #335291;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    margin-left: 5px;
    color: #335291;
}

.confirm-booking-new-table .form-control {
    display: inline-block;
}

.cargo-line-hazardous-btn .form-control {
    display: inline-block;
}

.confirm-booking-new-table .form-group,
.cargo-line-hazardous-btn .form-group {
    margin-bottom: 0;
}

.new-describe-your-box .form-control:focus {
    background: #fff !important;
}


/**********************confirm-booking-new-UI-END**************/


/******************Price Breakdown pop-up css -START **************/

.price-breakdown-table table {
    width: 100%;
}

.price-breakdown-table table thead tr {
    font-weight: normal;
    text-transform: uppercase;
    color: #748096;
    font-size: 12px;
    background: #f2f3f6;
    border-bottom: 2px solid #e5e5e5;
    border-radius: 0 0 0 0;
}

.price-breakdown-table table thead tr th {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 11px;
    padding: 6px 10px;
}

.price-breakdown-table table th:nth-child(1) {
    border-radius: 5px 0 0 5px;
}

.price-breakdown-table table th:nth-child(7) {
    border-radius: 0 5px 5px 0;
    text-align: right;
}

.price-breakdown-table td {
    font-size: 11px;
    padding: 3px 8px;
    color: #525967;
    font-weight: 600;
}

.price-breakdown-table table tbody tr:nth-child(4) {
    border-bottom: 1px solid #a5b6bc;
}

.price-breakdown-table table tbody tr:nth-child(7) {
    border-bottom: 1px solid #a5b6bc;
}

.price-breakdown-table table tbody tr:nth-child(8) {
    border-bottom: 1px solid #a5b6bc;
}

.price-breakdown-table table tbody tr:nth-child(13) {
    border-bottom: 1px solid #a5b6bc;
}

b.price-breakdown-table {
    font-size: 14px !important;
}

.price-breakdown-table table tbody td:nth-child(2) {
    color: #6b7281;
    font-weight: normal;
}

.price-breakdown-table table tbody td:nth-child(4) {
    text-align: center;
}

.price-breakdown-table table thead th:nth-child(4) {
    text-align: center;
}

.price-breakdown-table table tbody td:nth-child(5) {
    text-align: center;
}

.price-breakdown-table table thead th:nth-child(5) {
    text-align: center;
}

.price-breakdown-table table tbody td:nth-child(6) {
    text-align: center;
}

.price-breakdown-table table thead th:nth-child(6) {
    text-align: center;
}

.price-breakdown-table tbody tr {
    border-bottom: 1px solid #dbe2e5;
}

.modal-dialog.price-breackdown-popup {
    max-width: 60%;
}

.modal-dialog.price-breackdown-popup .modal-header {
    background: #335291;
    color: #fff;
    text-align: center;
    padding: 6px;
}

.modal-dialog.price-breackdown-popup .modal-header h5 {
    font-size: 16px;
    text-align: center;
    width: 100%;
}

.modal-dialog.price-breackdown-popup button.close {
    border: 0;
    -webkit-appearance: none;
    background: transparent;
    border-radius: 50%;
    position: absolute;
    right: 19px;
    color: #fff;
    padding: 6px 10px;
    top: 15px;
    opacity: 1;
}

.local-area a {
    border-radius: 20px;
    color: #fff;
    background: #21b0a1;
    padding: 4px 18px;
    margin: 3px 0;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
}

.local-area.color-one a {
    background: #22c55f;
}

td.change-td {
    font-weight: 600;
    color: #525967;
    text-align: right;
}

.price-breakdown-table table tbody td:nth-child(2).change-td.total-charge-last-one {
    font-weight: 600;
    color: #22c55f;
    text-align: right;
    font-size: 14px;
}

.price-breakdown-table table tbody td:nth-child(3).change-td.total-charge-last {
    font-weight: 600;
    color: #21b0a1;
    text-align: right;
    font-size: 14px;
}

.price-breakdown-table tbody tr.border-none {
    border-bottom: none;
}

.pol-view-price b {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    color: #05509b;
}

.pol-view-price p {
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: 500;
}

.pol-full-line {
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.price-breakdown-table td b {
    text-transform: uppercase;
    font-size: 11px;
}


/**********************Price Breakdown pop-up css-END**************/


/**********************get-quote css-STRAT***********************/

.grow-booking {
    background: #eaf5fe;
    border-radius: 20px;
}

.grow-booking h4 {
    font-size: 24px;
    font-weight: 600;
    padding: 20px 20px 0 20px;
    color: #335291;
    text-transform: uppercase;
}

.grow-booking button {
    border: 0;
    background: #f5d143;
    color: #335291;
    padding: 8px 20px;
    font-weight: 500;
    border-radius: 30px;
    margin-left: 20px;
    margin-top: 10px;
    cursor: pointer;
}

.grow-booking span {
    font-size: 12px;
    display: inline-block;
    width: 100%;
    padding-left: 20px;
    padding-top: 10px;
    color: #335291;
    font-weight: 500;
}


/**********************get-quote css-END**************/

.main-listing-right th .css-yk16xz-control {
    border-radius: 30px;
    font-weight: normal;
}

.main-listing-right th .css-1pahdxg-control {
    border-radius: 30px;
    font-weight: normal;
    border: 1px solid;
    box-shadow: none;
    font-size: 11px;
}

.main-listing-right th .css-1hwfws3 {
    font-size: 12px;
}

.main-listing-right th .css-tlfecz-indicatorContainer {
    padding: 1px 5px 0 0;
}

.main-listing-right th .css-1gtu0rj-indicatorContainer {
    padding: 1px 5px 0 0 !important;
}

.custom-error-msg {
    color: #dc3545;
    font-size: 80%;
    margin-top: 0.25rem;
    width: 100%;
}

.css-2613qy-menu .Select-value-label {
    font-size: 12px !important;
    font-weight: normal !important;
}

.css-1fhf3k1-control {
    font-weight: normal !important;
}

.main-listing-right th .search-select {
    font-size: 11px;
    font-weight: normal;
}

.form-modelnew-inn .react-datepicker-wrapper {
    display: inherit;
    width: 100%;
}

.enquiry-form-page label {
    font-size: 13px;
}

.enquiry-form-page .form-control {
    font-size: 13px;
    color: #000;
    border-radius: 0;
}

.error-t-negative.cargo-weight.form-group {
    width: 83%;
}

.error-t-negative.cargo-weight-value {
    width: 17%;
    height: 34px;
    margin-top: 29px;
}

.error-t-negative.cargo-weight-value input {
    background: #8e8e8e;
    color: #fff;
    border: 1px solid #ddd;
    padding: 7px;
    text-align: center;
}

.all-dive-av {
    display: flex;
}

.div-popup-all label {
    font-size: 12px;
}

.div-popup-all .form-group {
    margin-bottom: 10px;
}

.div-popup-all table .form-group {
    margin-bottom: 0;
}

.div-popup-all .form-group input {
    font-size: 14px;
}

.loading-text {
    font-size: 50px;
    text-align: center;
    margin-top: 50px;
    font-weight: 600;
    color: #00bbaf;
}

.loading-text-small {
    font-size: 25px;
    text-align: center;
    margin-top: 30px;
    color: #00bbaf;
}

.full-list-booking {
    margin-bottom: 50px;
}

.ul-in button {
    background: transparent;
    border: none;
    padding: 0;
}

.ul-in button:hover {
    background: transparent;
    border: none;
    padding: 0;
}

.ul-in button:active {
    background: transparent;
    border: none;
    padding: 0;
}

.ul-in .btn:not(:disabled):not(.disabled):active {
    background: transparent;
    border: none;
    padding: 0;
}

.popover.container-box img.img-container {
    margin: 0 auto;
    text-align: center;
}

.popover.container-box {
    margin: 0 auto;
    text-align: center;
}

.container-box ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.container-box ul li b {
    margin-right: 5px;
    width: 40px;
}

.container-box ul li {
    font-weight: normal;
    color: #335291;
    font-size: 12px;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.container-box ul li b span {
    margin-left: 2px;
}

.container-box ul li span {
    font-weight: normal;
    margin-left: auto;
}

.container-box ul li strong {
    margin-left: auto;
    margin-right: auto;
}


/****************** booking page *****************/

tr.tr-border-btm {
    border-bottom: 1px solid #ddd;
}

.confirm-booking-table td.bookoing-right-table {
    padding: 0 !important;
    vertical-align: middle;
}


/****************** booking page ******************/

.user-side-nav {
    background: #e7f6fd;
    border-radius: 20px;
    padding: 8px;
    margin-top: 30px;
    margin-bottom: 50px;
}

.user-side-nav a {
    text-decoration: none;
}

.user-side-nav .active {
    text-decoration: none;
    font-weight: bold;
}

.user-profile-picture {
    border-radius: 50%;
    height: 125px;
}

.user-side-nav ul {
    display: inline;
}

.user-side-nav li {
    padding: 5px 15px;
    font-size: 20px;
}

.home-page-success {
    color: green;
    text-align: center;
    margin-top: 5px;
    font-weight: 500;
}

.vgm-modal-title {
    text-transform: uppercase;
}


/****************** address-book START*****************/

.add-line-bg {
    padding: 8px 15px;
    background: #335292;
    margin-top: 30px;
    display: inline-flex;
    width: 100%;
    border-radius: 20px;
    align-items: center;
}

.add-card button {
    background: transparent;
    padding: 0;
    border: 0;
    line-height: auto;
    font-size: 14px;
    text-transform: uppercase;
    color: #000;
    width: 100%;
}

.add-card {
    border: 2px dotted #ddd;
    display: flex;
    width: 100%;
    justify-content: center;
    min-height: 270px;
}

.add-card .add-btn-icon i {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    border: 2px dotted #ddd;
    font-size: 30px;
    padding: 20px 0;
    margin: 0 auto;
    text-align: center;
    color: #b5b5b5;
}

.add-card .add-btn-icon {
    margin-bottom: 15px;
}

.add-card span {
    border: 1px solid #ddd;
    padding: 7px 15px;
    color: #b5b5b5;
}

.add-card button:hover {
    background: transparent;
    padding: 0;
    border: 0;
    line-height: auto;
    color: #000;
}

.add-line-bg h1 {
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 0;
    vertical-align: middle;
    margin-top: 0;
}

.address-add-view .card-body {
    padding: 10px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 0;
    box-shadow: 11px 11px 1px -8px #ddd;
    margin-bottom: 31px;
    min-height: 270px;
    position: relative;
}

.add-card:hover .add-btn-icon i {
    border: 2px dotted #179ad2;
}

.address-add-view {
    margin-top: 30px;
}

.address-add-view .card-body p {
    margin-bottom: 0;
    font-size: 14px;
    word-break: break-all;
}

.address-add-view .card-body h4 {
    margin-bottom: 5px;
    font-size: 16px;
}

.address-add-view .card-body p.color-chng {
    color: #828282;
    font-size: 13px;
}

.add-card .btn-primary:not(:disabled):not(.disabled):active {
    background: transparent;
}

.btn-end-card {
    position: absolute;
    bottom: 10px;
}

.btn-end-card button.btn-primary {
    color: #fff;
    background-color: #2fa0d4;
    border-color: #2fa0d4;
    border-radius: 0;
    padding: 2px 9px;
}

.btn-end-card button.btn-primary:hover {
    background-color: #0487c3;
    border-color: #0487c3;
}

.add-card:hover span {
    border: 1px solid #179ad2;
    color: #179ad2;
}

.add-card:hover {
    border: 2px dotted #179ad2;
}

.user-side-nav.booking-left-address .loading-text {
    font-size: 20px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
}

.booking-left-address h6 {
    text-align: center;
    font-size: 12px;
}

.user-side-nav.booking-left-address .profile-img {
    border: 1px solid #ddd;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    text-align: center;
}

.user-side-nav.booking-left-address .profile-img img {
    margin: 0 auto;
    text-align: center;
}

.user-side-nav.booking-left-address ul {
    padding: 0;
    display: inherit;
    margin: 35px 0 0;
}

.user-side-nav.booking-left-address ul li {
    padding: 0 0;
    margin: 4px 0;
}

.user-side-nav.booking-left-address ul li a {
    font-size: 14px;
    padding: 7px;
    background: #ddf1fb;
    width: 100%;
    display: inline-block;
    color: #179ad2;
}

.user-side-nav.booking-left-address a.active {
    text-decoration: none;
    font-weight: normal;
    background: #b0e7ff;
    color: #006692;
}

.user-side-nav.booking-left-address {
    background: #e7f6fd;
    border-radius: 20px;
    padding: 15px;
    margin-top: 30px;
    margin-bottom: 50px;
}

.add-address-popoup .modal-title h2 {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 0;
}

.add-address-popoup button.close {
    padding: 0;
    background-color: rgb(0, 123, 255);
    border: 0;
    -webkit-appearance: none;
    position: absolute;
    right: -11px;
    top: -11px;
    border-radius: 50%;
}

.add-address-popoup .modal-header .close {
    padding: 10px 13px;
    margin: 0;
}

.add-address-popoup .close {
    color: #fff;
    opacity: 1;
}

.add-address-popoup .upload-2-files .upload-btn-wrapper {
    padding-top: 5px;
}

.add-address-popoup button {
    padding: 6px 23px;
    margin-right: 10px;
    font-size: 13px;
}


/****************** address-book END*****************/

.popoverHazardous {
    font-size: 12px;
}


/******************* profile-START********************/

.profile-all {
    margin-top: 20px;
}

.profile-all label {
    font-size: 14px;
}

.profile-all input {
    font-size: 14px;
    border-radius: 0;
}

.profile-all .upload-2-files {
    text-align: left;
    margin: 0 auto;
}

.profile-all .upload-2-files img {
    width: 100px;
    height: 100px;
}

.profile-all .upload-2-files .btn {
    margin: 35px 0 0;
}

.profile-all .vgm_sub {
    display: flex;
}

.profile-all button {
    margin-left: auto;
}

.user-side-nav.booking-left-address .profile-img img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.company-all .company-logo-img img {
    width: 100px;
    height: 100px;
    margin-top: -27px;
    font-size: 12px;
}

.company-all .upload-2-files .btn {
    margin: 1px 0 0;
}

.company-all .upload-btn-wrapper {
    display: inherit;
    text-align: left;
}

.company-all .vgm_sub {
    display: flex;
}

.company-all button {
    margin-left: auto;
}

.company-all {
    margin-top: 20px;
}

.company-all label {
    font-size: 14px;
}

.company-all input {
    font-size: 14px;
    border-radius: 0;
}

.company-all textarea {
    font-size: 14px;
    border-radius: 0;
}

.css-yk16xz-control {
    border-radius: 0 !important;
    font-size: 14px;
}

.css-1pahdxg-control:hover {
    border-color: transparent !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #ddd !important;
}

.css-1pahdxg-control {
    border-color: transparent !important;
    box-shadow: none !important;
    border: 0 solid #ddd !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #ddd !important;
}

.css-1hwfws3 {
    font-size: 14px;
    border-radius: 0 !important;
}

.change-pass label {
    font-size: 13px;
    color: #000;
    margin-bottom: 0;
}

.change-pass input {
    font-size: 13px;
    color: #000;
    border-radius: 0;
    border-right: 0;
    border-left: 0;
    border-top: 0;
    padding: 0;
    border-bottom: 1px solid #000;
}

.change-pass form.inn-form {
    margin-top: 50px;
}

.change-pass .invalid-feedback {
    font-size: 12px;
}

.invalid-feedback-user {
    display: block;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.change-pass button {
    margin-right: 5px;
    border-radius: 30px;
    padding: 7px 29px;
}

.center-div-col {
    margin: 0 auto;
}


/******************* profile-END********************/

.select-tab-login {
    margin: 0 auto;
    text-align: center;
}

.enquiry-view-text b {
    color: #335291;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    display: inline-block;
}

.enquiry-view-text p {
    /*font-weight: 500;*/
    font-size: 13px;
    margin-bottom: 0;
}

.enquiry-view-head {
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    color: #05509b;
    margin-bottom: 20px;
    background: #a7e4ff;
    padding: 5px 8px;
    margin-top: 20px;
}

.enquiry-view-text {
    margin-bottom: 25px;
}

.round-select .css-yk16xz-control {
    border-radius: 20px !important;
}

.round-select .css-1pahdxg-control {
    border-radius: 20px !important;
    border: 1px solid #ddd !important;
}

.round-select .css-tlfecz-indicatorContainer,
.round-select .css-1gtu0rj-indicatorContainer {
    color: #375593;
}

.promo-code-input {
    background: #f2f5f6;
    border: none;
}

.promo-code-span {
    border: none;
    background: #6dcef0;
    color: #fff;
    padding: 13px 40px;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: 2px 6px 8px 4px #ddd;
    font-size: 13px;
}

.price-breakdown-table table tbody td:nth-child(7) {
    text-align: right;
}

.promo-code-section {
    text-align: center;
    margin-bottom: 5px;
}

.promo-code-section input {
    border-radius: 11px 0 0 11px;
    padding: 3px 10px;
    border: 1px solid #c4d2d7;
    width: calc(100% - 40px);
    border-right: none;
}

.promo-code-section input:disabled {
    background-color: #f6f8f8;
}

.promo-code-section button {
    border-radius: 0 11px 11px 0;
    padding: 3px 12px 3px 8px;
    border: 1px solid #c4d2d7;
    width: 40px;
    cursor: pointer;
    color: #335291;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #335291;
}

.new-cargo-contains-hazardous .container-lable input:checked ~ .checkmark {
    background-color: #335291;
}

.logi-register-all {
    background: #fff;
    padding: 0;
    border: 1px solid #ddd;
    margin: 0 0 50px 0;
    box-shadow: 6px 6px 6px 0 #ddd;
    border-radius: 10px;
}

.register-section {
    padding: 50px;

}

.login-section {
    padding: 30px 30px;
}

.logi-register-all-fwrgt {
    border: 1px solid #ddd;
    box-shadow: 6px 6px 6px 0 #ddd;
    border-radius: 10px;
    padding: 50px;
    margin: 50px 0;
}

.logi-register-all-fwrgt .login-section {
    padding: 0;
}

.logi-register-all-fwrgt .login-btn a {
    display: inline-block;
    width: 100%;
    text-align: right;
}

.logi-register-all-fwrgt .login-section .login-btn {
    margin: 0 auto 0;
    text-align: center;
}

.border-div {
    border-left: 1px solid #ddd;
}

.login-section .form-control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    padding: 0 0 5px 0;
    margin-bottom: 0;
}

.login-section .form-control .custom-control-inline {
    padding-top: 4px;
}

.login-section .btn-primary {
    color: #fff;
    background-color: #335291;
    border-color: #335291;
    padding: 10px 70px;
    border-radius: 30px;
    margin: 0 auto;
    text-align: center;
}

.login-section label {
    font-size: 12px;
    text-transform: uppercase;
    color: #335291;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 0;
    cursor: pointer;
}

.login-section .login-btn {
    margin:10px auto 0;
    text-align: center;
}

.forget-login-psss {
    text-align: right;
}

.forget-login-psss a {
    text-align: right;
    font-size: 14px;
    color: #4fa7d2;
}

.register-section.login-section fieldset {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 30px;
    display: inline-block;
    box-shadow: 6px 6px 6px 0 #ddd;
}

.register-section.login-section fieldset .form-control {
    margin-bottom: 0;
}

.register-section.login-section label.select-leb {
    width: 100%;
    font-size: 14px;
    color: #4fa7d2;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.menu-responsive {
    display: flex;
    vertical-align: middle;
    align-items: center;
}

.custom-login-class {
    display: none;
}

.logi-register-all h5 {
    text-transform: uppercase;
    font-size: 25px;
    margin-bottom: 20px;
    border-bottom: 1px solid #4fa7d2;
    padding-bottom: 10px;
    color: #335291;
}

.new-cargo-contains-hazardous {
    margin-top: 0;
    display: flex;
    margin-bottom: 20px;
}

.promo-code-section a.probtn {
    border-radius: 0 11px 11px 0;
    padding: 5px 12px 6px 8px;
    border: 1px solid #c4d2d7;
    width: 40px;
    cursor: pointer;
    color: #335291;
    background: #e7f6fd;
}

/*bill*/

.heading-bill-lading {
    background: #05509b;
    padding: 10px 16px;
    margin-top: 37px;
    display: flex;
    border-radius: 30px;
}

.heading-bill-lading a {
    font-size: 13px;
    margin-left: auto;
    background: #4aa4ff;
    padding: 4px 12px;
    border-radius: 20px;
    color: #fff;
    text-decoration: none;

}

.heading-bill-lading h1 {
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 0;
    padding: 7px 0 0 0;
}

.bil-new-form {
    box-shadow: 0 3px 8px 0 #ddd;
    padding: 20px;
    border-radius: 0 0 8px 8px;
}

.fied-bl label {
    font-size: 13px;
    text-transform: uppercase;
    color: #a5a5a5;

}

.fied-bl .form-control {
    border-radius: 0;
}

.fied-bl select.form-control:not([size]):not([multiple]) {
    font-size: 13px;
}

.fied-bl .btn-primary {
    color: #fff;
    background-color: #00acf0;
    border-color: #007bff;
    border-radius: 30px;
    font-size: 14px;
    padding: 7px 27px;
    margin: 29px 0 0 0;
}

.fied-bl .btn-primary:hover {
    background-color: #007bff;
}


tr.expandable > td {
    box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, .2);
    padding: 0;
}

tr.expandable > td > .inner {
    margin: 15px;
    overflow: hidden;
}

.container-main-tr {
    background-color: white !important;
    height: 46px !important;
}

.slide-table {
    width: 100% !important;
}

.slide-table table {
    width: 100% !important;
    background: #f7f7f7;
    border: 0 solid #ddd;
}

.slide-table table tr td table {
    width: 100%;
    border: 0 solid #ddd;
    background: #fff;
    border-radius: 0 0 10px 10px !important;
    box-shadow: 0 6px 5px -2px #ddd !important;
}

.slide-table table tr td table th {
    border-radius: 0 !important;
    background: #f3f6fd !important;
    color: #179ad2 !important;
}

.slide-table table th {
    background: #179ad2;
    /*border: 1px solid #ddd;*/
    padding: 5px;
    color: #fff;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: normal;
}

.slide-table table th:nth-child(1) {
    border-radius: 6px 0 0 6px;
}

.slide-table table th:nth-child(7) {
    border-radius: 0 6px 6px 0;
}

.slide-table table td {
    /*border: 1px solid #ddd;*/
    padding: 5px;
    font-size: 12px;
    color: #0a6c96;
}

.note-text-line {
    margin-top: 0;
}

.note-text-line p {
    color: #00aaf3;
    font-size: 14px;
}

.bill-of-lading-data .text-muted {
    margin-bottom: 0;
    color: #000 !important;
}

.bill-of-lading-data .ReactTable .rt-td {
    padding: 15px 8px;
    white-space: inherit;
    font-size: 13px;
}

.bill-of-lading-data .ReactTable .rt-td:nth-child(5) {
    text-align: center;
}

.bill-of-lading-data .ReactTable .rt-thead .rt-th:nth-child(5) {
    text-align: center;
}

.bill-of-lading-data .ReactTable .rt-td:nth-child(6) {
    text-align: center;
}

.bill-of-lading-data .ReactTable .rt-thead .rt-th:nth-child(6) {
    text-align: center;
}

.bill-of-lading-data .ReactTable .rt-thead .rt-th {
    white-space: inherit;
    font-size: 13px;
    padding: 15px 8px;
    text-align: left;
}

.bill-of-lading-data .badge-secondary {
    color: #fff;
    background-color: #4aa4ff;
    padding: 8px 0;
    width: 30px;
    height: 30px;
    font-size: 15px;
    border-radius: 50%;
    text-align: center;
}

.bill-of-lading-data .badge-secondary:hover {
    background-color: #05509b;
}

.bill-of-lading-data .pagination-bottom {
    padding: 0 20px;
    font-size: 13px;
}

.bill-of-lading-data .pagination-bottom span {
    font-size: 13px;
    color: #969696;
}

.bill-of-lading-data .pagination-bottom .btn-outline-primary {
    color: #969696;
    background-color: transparent;
    background-image: none;
    border-color: #ddd;
    padding: 2px 11px;
    font-size: 13px;
}

.div-static-data-full.new-blfrom {
    padding: 20px;
    border: 1px solid #ddd;
}

.div-static-data-full.new-blfrom h3 {
    margin-bottom: 0;
    font-size: 18px;
}

.div-static-data-full.new-blfrom .card-header {
    background-color: rgb(231, 246, 253);
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 20px;
    border-bottom: 0;
}

.div-static-data-full.new-blfrom th {
    font-size: 13px;
    font-weight: normal;
    background: #f9f9f9;
}

.div-static-data-full.new-blfrom td {
    font-size: 13px;
}

.div-static-data-full.new-blfrom input {
    font-size: 13px;
    border-radius: 0;
    padding: 5px;
    height: 30px;
    width: 100%;
    border: 1px solid #ddd;

}

.div-static-data-full.new-blfrom .modal-data-input textarea {
    font-size: 13px;
    border-radius: 0;
}

.div-static-data-full.new-blfrom label {
    font-size: 12px;
    text-transform: uppercase;
    color: #000;
}

.div-static-data-full.new-blfrom .bl_sub button {
    font-size: 13px;
    padding: 9px 32px;
}

.div-static-data-full.new-blfrom .css-tlfecz-indicatorContainer {
    padding: 4px;
}

.div-static-data-full.new-blfrom .css-yk16xz-control {
    font-size: 13px;
    height: auto;
    color: #000;
    min-height: auto !important;
}

.div-static-data-full.new-blfrom .css-1pahdxg-control:hover {
    box-shadow: none;
    border-radius: 0 !important;
    border: 1px solid #ced4da !important;
    font-size: 13px !important;
    height: auto;
    min-height: auto !important;
    color: #000;
}

.div-static-data-full.new-blfrom .css-1pahdxg-control .css-1gtu0rj-indicatorContainer {
    padding: 4px;
}

.div-static-data-full.new-blfrom .css-1pahdxg-control {
    box-shadow: none;
    border-radius: 0 !important;
    border: 1px solid #ced4da !important;
    font-size: 13px !important;
    height: auto;
    color: #000;
    min-height: auto !important;
}

.bil-new-form .css-1pahdxg-control {
    border: 1px solid #ced4da !important;
    font-size: 13px !important;
}

.bil-new-form .css-yk16xz-control {
    font-size: 13px !important;
    color: #000;
}

.bil-new-form .css-2613qy-menu {
    font-size: 13px !important;
}

.bil-new-form .css-2613qy-menu .Select-value-label {
    font-size: 12px !important;
    font-weight: normal !important;
}

.bil-new-form .custom-control {
    display: inline-block;
}

.bill-of-lading-data .ReactTable .-pagination .-btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 40px;
    height: 40px;
    border: 0;
    border-radius: 50%;
    padding: 6px;
    font-size: 1em;
    color: rgba(0, 0, 0, 0.6);
    background: #05509b;
    transition: all .1s ease;
    cursor: pointer;
    outline-width: 0;
    opacity: 1 !important;
    color: #fff;
}

.bill-of-lading-data .pagination-bottom {
    padding: 0 0;
    font-size: 13px;
}

.bill-of-lading-data .ReactTable .-pagination {
    padding: 8px 20px;
}

.bill-of-lading-data .-next .-btn {
    float: right;
}

.freight-chrg-btn {
    border: 1px solid #57bfec;
    background: #a7e4ff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-left: 10px;
    font-size: 12px;
    color: #335292;
    cursor: pointer;
}

p.freight-p {
    display: inline-block;
}

p.check-box-textp {
    display: inline-block;
    margin-bottom: 0;
    padding-top: 6px;
    vertical-align: middle;
}

.start-bl-btn {
    margin-top: 12px;
}

.bl_container_tr {
    background-color: lightgrey;
}

.note-text-added {
    background: #f7fdff;
    padding: 10px;
    margin-bottom: 40px;
    margin-top: 20px;
}

.chang-in-co {
    text-align: left;
    margin-bottom: 0;
    font-size: 11px;
    color: #05509b;
}

.change-bg-in {
    display: inline-block;
    margin-bottom: 0;
}

.enquiry-add-btn a:hover {
    background: #2e79c7;
}

.enquiry-add-btn a {
    background: #4177b0;
    width: 100%;
    display: inline-block;
    color: #fff;
    text-align: center;
    padding: 6px;
    border-radius: 20px;
    text-decoration: none;
}

.enquiry-add-btn {
    /*margin-top: 10px;*/
}

.enquiry-details-button {
    padding: 5px 10px;
    border-radius: 20px;
    color: #ffffff;
    cursor: pointer;
    border: none;
    background: #335292;
    font-size: 13px;
    margin: 4px 1px;
}

.enquiry-details-button a {
    color: #ffffff;
}

.remark {
    margin-top: 20px;
}

.invalid-feedback-user {
    margin-top: 0;

}

.kindly-text {
    background: #e7f6fd;
    border-radius: 12px;
    border: 1px solid #ddecf3;
    padding: 15px 15px 15px 15px;
    box-shadow: 4px 5px 11px -11px #000;
    margin-bottom: 20px;
}

.kindly-text p {
    margin-bottom: 0;
    font-weight: 500;
    color: #335291;
}

.kindly-text p a {
    border-radius: 20px;
    border: 1px solid #335291;
    font-size: 13px;
    padding: 5px 20px;
    background: #335291;
    color: #fff;
    margin: 0 10px;
    font-weight: normal;
}

.modal-header {
    padding: 10px;
}

.modal-header .modal-title {
    margin-bottom: 0;
    font-size: 18px;
}

.modal-header .modal-title h4 {
    margin-bottom: 0;
    font-size: 18px;
}

.new-bl-pop .custom-control {
    display: inline-block;
}

.cancel-booking {
    background: #a33f3f !important
}

.add-address-popoup label {
    font-size: 11px;
    text-transform: uppercase;
    margin-bottom: 0;
}

.add-address-popoup .form-control, .add-address-popoup .css-g1d714-ValueContainer {
    border-radius: 0;
    font-size: 12px;
    height: 38px;
}

.submit-btn-pop {
    text-align: center;
}

.add-address-popoup .form-group {
    margin-bottom: 5px;
}

.add-address-popoup .invalid-feedback {
    font-size: 11px;

}

.add-address-popoup p {
    font-size: 11px;
    margin-bottom: 0;
    color: #dc3545;
}

.modal-dialog.add-address-popoup {
    max-width: 800px;
}

.add-btn-address-plus {
    margin-left: auto;
}

.add-btn-address-plus button {
    font-size: 11px;
    border-radius: 30px;
    text-transform: uppercase;
    color: #fff;
    background-color: #05509b;
    border-color: #05509b;
}

.add-btn-address-plus button:hover {
    background-color: #0d5398;
    border-color: #0d5398;
}

.table-view-add .ReactTable .rt-tbody .rt-td:last-child {
    border-right: 0;
    text-align: center;
}

.table-view-add p {
    margin-bottom: 0;
    font-size: 13px;
}

.table-view-add .badge-pill {
    background-color: #05509b;
    padding: 8px 0;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    cursor: pointer;
}

.table-view-add .badge-pill:hover {
    background-color: #0d5398;
}

.add-btn-address-plus button:not(:disabled):not(.disabled):active {
    background-color: #0d5398;
    border-color: #0d5398;
}

.table-view-add .ReactTable .rt-thead .rt-resizable-header-content {
    font-size: 13px;
    text-transform: uppercase;
    padding: 6px 0;
}

.address-book-view-pop p {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 0;
    color: #989898;
}

.address-book-view-pop b {
    font-weight: normal;
    font-size: 13px;
    color: #000;
}

.address-book-view-pop .form-group {
    margin-bottom: 15px;
    display: inline-block;
}

.address-book-view-pop .upload-2-files .upload-btn-wrapper {
    padding-top: 0;
}

.add-address-popoup .modal-header {
    background: #d4e9ff;
}

.add-address-popoup .modal-body {
    max-height: 500px;
    overflow-y: scroll;
}

.table-view-add .ReactTable .-pagination input, .table-view-add .ReactTable .-pagination select {
    font-size: 13px;
}

.table-view-add .ReactTable .-pagination .-pageInfo {
    font-size: 13px;
}

.table-view-add .ReactTable .-pagination .-btn {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    text-align: center;
    font-size: 11px;
    background: #179ad2;
    color: #fff;
}

.table-view-add .ReactTable .-pagination .-btn:hover {
    background: rgb(7, 122, 171);
}

.table-view-add .ReactTable .-pagination .-previous button {
    float: left;
}

.table-view-add .ReactTable .-pagination .-next button {
    float: right;
}

p.consignee-name-line {
    font-size: 8.5px;
    font-weight: 500;
    padding: 5px 0;
    color: red;
}

.row-overflow .rt-th, .row-overflow .rt-td {
    overflow: inherit !important;
}

.new-change-confirm-booking input {
    border-radius: 0;
    font-size: 14px;
    padding: 10px 8px;
    background: #fff !important;
    border: 1px solid #ddd;
    width: 100%;
}

.new-change-confirm-booking textarea {
    background: #fff !important;
}

.new-change-confirm-booking .form-control {
    background: transparent;
}

.new-change-confirm-booking select.form-control:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px);
    background: #fff;
    border-radius: 0;
}

.new-change-confirm-booking label {
    font-size: 14px;
    margin-bottom: 4px;
}

.form-edit-text .css-1uccc91-singleValue {
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 12px;
    min-height: auto !important;
}

.form-edit-text .css-yk16xz-control {
    min-height: auto !important;
    border-color: #c6e2f3 !important;
}

.form-edit-text .css-yk16xz-control:hover {
    border-color: #c6e2f3 !important;
    font-size: 14px;
    min-height: auto !important;
}

.form-edit-text .css-1pahdxg-control:hover {
    border: 1px solid #c6e2f3 !important;
    font-size: 14px;
    min-height: auto !important;
}

.form-edit-text .css-1pahdxg-control {
    border: 1px solid #c6e2f3 !important;
    font-size: 14px;
    min-height: auto !important;
}

.form-edit-text .css-tlfecz-indicatorContainer {
    padding: 3px;
}

.form-edit-text .css-1pahdxg-control:hover .css-tlfecz-indicatorContainer {
    padding: 3px;
}

.commodities-div {
    margin-bottom: 20px;
}

.commodities-div p {
    font-size: 14px;
    margin-top: 0;
    color: #f00;
    margin-bottom: 0;
    text-align: center;
}
.note{
    font-size: 14px;
    margin: 10px auto;
    /*margin-top: 0;*/
    color: #f00;
    /*margin-bottom: 0;*/
}
.textCenter{
    text-align: center;
}

.upload-2-files.hazardous-uplod-file {
    text-align: left;
    margin-bottom: 25px;
}

.price-breakdown-table.modal-body {
    overflow-x: auto;
    min-height: 550px;
    height: 550px;
    padding: 10px 0;
}

.price-breakdown-table.cancel_booking-popup {
    min-height: auto;
    height: auto;
}

.modal-dialog.price-breackdown-popup.cancel_booking-popup {
    max-width: 50%;
}

.upload-2-files.hazardous-uplod-file p {
    font-size: 13px;
    color: #000000;
    margin-bottom: 0;
}

.vgm-div-box-right.vgm-div-box-hazardous-uplod-file {
    text-align: left;
    margin-bottom: 20px;
}

.vgm-div-box-right.vgm-div-box-hazardous-uplod-file ul {
    text-align: left;
}

.vgm-div-box-right.vgm-div-box-hazardous-uplod-file li {
    margin-bottom: 4px;
    margin-right: 4px;
}

p.table-full-price-note {
    font-size: 12px;
}

.pop-data-type-container .form-control input:-internal-autofill-selected {
    color: #000000 !important;

}

.modal-dialog.price-breackdown-popup .modal-body {
    min-height: 550px;
    height: 550px;
    overflow-y: auto;
}

.vgm-section-heding {
    margin-top: 10px;
    margin-bottom: 0;
    width: 100%;
}

.vgm-section-heding h1 {
    font-size: 14px;
    color: #006692;
    border-bottom: 1px solid #bacffb;
    padding-bottom: 3px;
    text-transform: uppercase;
}

.invalid-feedback {
    font-size: 11px !important;
    padding-bottom: 0;
    width: 100%;
}

.container-div {
    vertical-align: middle;
    padding: 25px 0 0 0;
}

.vgm_sub {
    text-align: center;
}

.vgm-section-heding span.only-file-div {
    font-size: 12px;
}

.vgm-bor-bottom {
    border: 1px solid #ddd;
    padding: 10px;
}

.booking-view-text-full.form-13-div b {
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    color: #000;
}

.booking-view-text-full.form-13-div label {
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    color: #000;
}

.booking-view-text-full.form-13-div p {
    font-size: 14px;
    font-weight: bold;
    color: #000;
}

.booking-view-text-full.form-13-div input {
    font-size: 14px;
    font-weight: bold;
    color: #000;
}

.booking-view-text-full.form-13-div select {
    font-size: 14px;
    font-weight: bold;
    color: #000;
}

.submit-btn-from-13 {
    margin: 15px auto;
    text-align: center;
    display: block;
}

.cancel-btn-filed {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.cancel-btn-x {
    margin-left: 8px;
}

.cancel-btn-x a {
    cursor: pointer;
    border: 1px solid #006692;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    padding: 2px 0;
    color: #006692 !important;
}

.cancel-btn-filed .form-group {
    margin-bottom: 0;
}

.cancel-btn-file {
    padding: 0;
    margin: 0;
    list-style: none;
}

.cancel-btn-file {
    display: flex;
}

.cancel-btn-file .badge-secondary {
    background: transparent
}

.vgm-section-heding input {
    font-size: 12px;
    overflow: hidden;
}

.cancel-btn-file i.color-changes {
    cursor: pointer;
    border: 1px solid #006692;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    padding: 6px 0;
    color: #006692 !important;
}

.check-adox-div {
    text-align: center;
    margin: 0 auto;
}

.form-edit-text .css-yk16xz-control {
    border-radius: 0 !important;
    font-size: 9px !important;
    height: auto;
}

/* .form-edit-text  .css-yk16xz-control:hover{
    padding: 3px!important;
} */
.form-edit-text .css-1pahdxg-control:hover {
    border-radius: 0 !important;
    font-size: 9px !important;
    height: auto;
}

.form-edit-text .css-1gtu0rj-indicatorContainer {
    padding: 3px !important;
}

.form-edit-text .css-1gtu0rj-indicatorContainer:hover {
    padding: 3px !important;
}

.react-datepicker-popper {
    z-index: 2;
}

.confirm-booking-new-table-date .react-datepicker__time-container {
    width: 27% !important;
}

.confirm-booking-new-table-date .react-datepicker__month-container {

    width: 73% !important;
}

.confirm-booking-new-table-date .react-datepicker {
    width: 323px !important;
}

.check-adox-div {
    margin-top: 20px;
}

.confirm-booking-new-table-date .react-datepicker-wrapper {
    width: 100%;
}

.adress-in-1 textarea {
    margin-top: 5px;
}

.booking-history-heading.no-booking a.no_booking_btn_click_here {
    background: #4177b0;
    display: inline-block;
    color: #fff;
    text-align: center;
    padding: 6px 10px;
    font-size: 16px;
    border-radius: 20px;
    text-decoration: none;
    text-transform: capitalize;
    letter-spacing: 1px;
    vertical-align: middle;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 30px;
    height: 30px;
    line-height: 31px;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 26px;
    height: 26px;
    line-height: 26px;
}

.adress-in-1 .address-line-up textarea {
    margin-top: 0;
}

.modal-dialog.price-breackdown-popup.cancel_booking-popup .modal-body {
    min-height: auto;
    height: auto;
    overflow-y: auto;
}

.full-pdf-submit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pdf-dwon-btn ul {
    padding: 0;
    margin: 14px 15px 0 0;
    list-style: none;
}

.pdf-dwon-btn ul li a {
    cursor: pointer;
}

.pdf-dwon-btn ul li a img {
    margin-right: 10px;
    margin-top: -4px;
}

.required {
    color: red;
}

.bill-of-lading-data .css-g1d714-ValueContainer {
    padding: 0 8px;
}

.bill-of-lading-data .css-tlfecz-indicatorContainer {
    padding: 0;
}

.bill-of-lading-data .css-yk16xz-control {
    min-height: auto !important;
}

.div-static-data-full.new-blfrom select.form-control:not([size]):not([multiple]) {
    border-radius: 0;
    font-size: 13px;
    padding: 5px;
    height: 30px;
}
.align-freight {
    margin: 0 0 0 10px;
}
.border-right1 {
    border: 1px solid #8093ba;
    padding: 15px;
}
/* input#user_freight_agreed_chrages_20 {
    padding: 5px;
}
input#user_freight_agreed_chrages_40 {
    padding: 5px;
}
input#user_freight_agreed_chrages_40hc {
    padding: 5px;
} */
/* input#freight_chrages_20 {
    padding: 5px;
} */
/* input#freight_chrages_40 {
    padding: 5px;
} */

/*
* accordian CSS
*/
.accordion-header {
    background: #145388;
    color: #fff !important;
    border-radius: 0 !important;
    font-size: 14px !important;
    font-weight: 700;
}

.accordion-header:hover {
    background: #104a7a;
    color: #fff !important;
    text-decoration: none !important;
}

button.accordion-header:focus {
    text-decoration: none !important;
}

.bell-icon-header {
    margin-right: 35px;
}

.bell-icon-header ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
}

.bell-icon-header ul li {
    display: flex;
    margin: 0 10px;
}

.bell-icon-header ul li a {
    position: relative;
}

.bell-icon-header ul li span {
    border-radius: 10px;
    text-align: center;
    background: #dc3562;
    color: #fff;
    position: absolute;
    top: -12px;
    font-size: 8px;
    padding: 2px;
    left: 15px;
    width: auto;
    height: auto;
}

.bell-icon-header ul li span.bell-2 {
    background: #ff7600;
    color: #fff;
}

.bell-icon-header ul li span.bell-3 {
    background: #ce214f;
    color: #fff;
}
.company-logo-img{
    margin-top: 30px;
}

/*
Placeholder css
*/
.text-box :-webkit-input-placeholder { /* Edge */
    color: #908686dd;
}

.text-box :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #908686dd;
}

.text-box ::placeholder {
    color: #908686dd;
}
.mob-fg-logo {
    display: flex;
    justify-content: space-around;
    width: 100%;
}
.heading-logo{
    margin: auto;
}
.heading-logo h1{
    font-size: 16px;
    margin-bottom: 0;
    border-top: 1px solid #335291;
    color: #335291;
    border-bottom: 1px solid #335291;
    padding-top: 4px;
    padding-bottom: 4px;
}
.text-touppercase {
    text-transform: uppercase;
}

.modal {
    background: rgb(0 0 0 / 78%);
}
.add-address-popoup .modal-footer {
    /*justify-content: center;*/
}

.checklist-table {
    margin: 20px 0;
}

.gst-field .form-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 20px 5px 0;
}
.gst-field a{
    font-size: 11px;
    text-transform: uppercase;
}
.error-color {
    color: #dc3545 !important;
}
.up-case {
    text-transform: uppercase;
  }

.text-capitalize {
    text-transform: capitalize;
}

.tag-container {
    min-width: 60%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    min-height: 30px;
    border: 1px solid #dfd8d8;
    border-radius: 2px;
    padding: 0px;
  }
  
  .tag {
    display: flex;
    align-items: center;
    padding: 5px;
    border: 1px solid gray;
    border-radius: 5px;
    height: 25px;
    margin: 2px 5px 2px 0px;
    color: #000000;
  }
  
  .tag-container input {
    border: none;
    flex: 1;
    outline: none;
    padding: 5px;
    background: #ffffff;
    color: #070707;
  }
  
  .tag span {
    margin-left: 5px;
    cursor: pointer;
  }
.login-head .heading-logo {
    padding-right: 221px;
}

.select-box select.form-control:not([size]):not([multiple]) {
    height: 30px;
    font-size: 14px;
}
.checked-box-label label{
    font-size: 18px;
    margin-bottom: 0;
    margin-right: 5px;
    cursor: pointer;
}
.checked-box-label .custom-control-label::before {
    width: 20px;
    height: 20px;
}
.checked-box-label .custom-control-label::after {
    width: 20px;
    height: 20px;
}
.checked-box-label{
    margin-bottom: 10px;
    border-bottom: 1px solid #b8cfff;
    padding-bottom: 10px;
}
   
.freight-label-box label{
       font-size: 18px;
    margin-bottom: 10px;
}
.freight-label-box b{
    font-weight: 400;
    color: #007bff;
}
.checked-box-label .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0 #fff, 0 0 0 0 rgba(0, 123, 255, 0.25);
}
.freight-required-requested{
    margin-bottom: 10px;
}
.submit-button-box{
       text-align: center; 
}
.submit-button-box .btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    padding: 10px 56px;
    margin-top: 20px;
    border-radius: 40px;
}
.password-div{
    position:relative
}
.password-eye{
    position:absolute;
    top:21px;
    right:10px;
}
.profile-address-file-ui{
    font-size: 14px;
    overflow-wrap: anywhere;
}
.text-right{
    text-align: right;
}
.text-left{
    text-align: left;
}
.text-wrap{
    /*text-wrap: auto !important;*/
    white-space: pre-wrap;
    word-break: break-word;
}
.m-10-ta-l{
    margin: 10px 0 0 10px;
    text-align: left;
}
.mtb-10px{ margin: 10px auto !important; }
.mt-2{ margin-top: 2% !important; }
.mb-2px{ margin-bottom: 2px !important; }
.mr-5px{ margin-right: 5px !important; }
.display-block{ display: block; }
.display-none{ display: none; }
/* margin left */
.mr-40per{ margin-left: 40% !important; }
/* margin top */
.mt-2per{ margin-top: 2% !important; }
.mt-10px{ margin-top: 10px !important;}
.mt-0px{ margin-top: 0px !important;}
.mt-20px{ margin-top: 20px !important;}
.mt-30px{ margin-top: 30px !important;}
/*margin bottom */
.mb-0px{ margin-bottom: 0px !important; }
.mb-10px{ margin-bottom: 10px !important; }
/* padding */
.p-0px{ padding: 0px !important; }
.p-auto{ padding: auto; }
/* line height */
.lh-32px{ line-height: 32px !important; }
.fs-13px{ font-size: 13px !important; }
.fs-12px{ font-size: 12px !important; }
.fs-14px{ font-size: 14px !important; }
.fs-130px{ font-size: 130px !important; }
.mx-w-150px{ max-width: 150px !important; }
.mx-w-10px{ max-width: 10px !important; }
.mx-h-150px{ max-height: 150px !important; }
.border-left-1px-lightblue{
    borderLeft: "1px solid #c4d2d7";
}
.brd-r-25px-p-4-10px {
    border-radius: 25px;
    padding: 4px 10px;
}
.text-red{ color: #dc3545; }
.text-green{ color: green; }
.text-black1{ color: #211f1f; }
.w-50px{ width: 50px !important; }
.w-40px{ width: 40px !important; }
.h-50px{ height: 50px !important; }
.h-40px{ height: 40px !important; }
.w-20px{ width: 20px !important; }
.h-20px{ height: 20px !important; }
.w-100per{ width: 100%  !important;}
.resize-none{ resize: none; }