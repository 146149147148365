@media (max-width: 320px) {    
    .search-input-file-cng input {
        width: 89%;
    }
    .kindly-text p {
        font-size: 14px;
    }   
    .forget-login-psss .btn-primary {
        padding: 8px 35px !important;
    } 
    .login-section .btn-primary {
        padding: 8px 35px !important;
    }
}


@media (max-width: 600px) {    
    /* .container-fluid.co-p {
        padding: 0;
        height: auto;
        vertical-align: middle;
        top: 0;
        transform: translateY(0%);
        position: inherit;
        right: 0;
        left: 0;
        margin: 0 auto;
    } */

    body {
        padding-top: 126px;
        padding-bottom: 0;
    }
    .banner-left-text h1 {
        font-size: 15px;
        display: flex;
    }
    .banner-left-text br.mob-none{
        display: none;
    }
    .banner-left-text h1 span {
        margin-left: 2px;
    }
    .option-in .css-1hwfws3 {
        padding: 0;

    }

    .banner-left-text ul li {
        margin: 2px 0;
        font-size: 9px;
    }

    .banner-tab-view .nav-tabs .nav-item.show .nav-link,
    .banner-tab-view .nav-tabs .nav-link.active {
        padding: 5px 15px;
        font-size: 13px;
    }

    .banner-left-text ul {
        margin-top: 12px;
    }

    .option-form {
        margin-top: 0;
    }

    .stats span {
        color: #000;
        font-size: 14px;
    }

    #counter-stats {
        position: inherit;
        margin-top: 15px;
    }

    .counting {
        font-size: 0;
    }

    #counter-stats h5 {
        color: #000;
        font-size: 8px;
        font-weight: 600;
    }

    .option-form {
        display: inherit;
    }

    .banner-tab-view .tab-content {
        width: 100%;
    }

    .banner-tab-view .tab-content>.tab-pane {
        padding: 0 !important;
    }

    .option-in .css-1wa3eu0-placeholder {
        font-size: 12px;
    }

    .icon-form img {
        margin: 0 auto;
        text-align: center;
    }

    .icon-form {
        margin: 13px auto;
        text-align: center;
    }

    .option-in {
        margin: 8px auto 0;
    }

    .img-boat {
        display: none;
    }

    .option-in .react-date-picker__wrapper {
        padding: 0 0;
    }

    .menu-full .navbar-brand img {
        width: 30px;
    }

    .menu-full .navbar-brand {
        padding: 0;
        margin-right: auto;
        margin-left: 0;
        margin-bottom: 0;
    }

    .menu-full .navbar {
        padding: 15px 10px;
        /* border-radius: 0 0 50px 50px; */
    }

    .search-btn a {
        padding: 5px 30px;
        font-size: 14px;
    }

    .react-date-picker__inputGroup {
        font-size: 12px;
    }

    .search-btn {
        margin-top: 15px;
        margin-bottom: 20px;
    }

    .menu-full .navbar-toggler {
        padding: 0px;
    border: none;
    }
  

    .banner-tab-view .card-header {
        margin: 12px auto 0;
    }

    .option-in .react-calendar__tile {
        padding: 5px;
        font-size: 12px;
    }

    .option-in .react-calendar button {
        font-size: 13px;
    }

    .option-in .react-calendar__navigation {
        height: 36px;
        margin-bottom: 0;
    }

    .water ul.waves li.wave-one {
        height: 14px;
    }

    .water ul.waves li.wave-two {
        height: 37px;
    }

    .water ul.waves li.wave-three {
        height: 32px;
    }

    .water ul.waves li.wave-four {
        height: 50px;
    }

    .boat-wave-in .plane-body {
        background-repeat: no-repeat;
        background-size: 120px;
    }

    .boat-wave-in ul.no-bullet {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .boat-wave-in .main {
        height: 10%;
        bottom: 0;
    }    
    .history-heading {
        top: 72px;
    }
    .menu-full .navbar-light .navbar-nav .nav-link {
        padding: 11px 0;
        text-align: center;
        border-bottom: 1px solid #b1daec;
        display: inline-block;
        width: 180px;
    }
    .menu-full .navbar-light .nav-item:nth-child(5) .nav-link{
        border-bottom: none;
    }
    .menu-full ul {
        margin-top: 12px;
        margin-left: 0;
        padding: 0;
        /* border-top: 1px solid #b1daec; */
        text-align: center;
        /* height: 100vh; */
    }

    .fixed-top {
        position: fixed;
    }

    .pop-up-login .text-model {
        display: none;
    }

    .pop-up-login .p-left-0 {
        padding-left: 15px;
    }

    .modal-dialog {
        width: 100%;
        max-width: 90% !important;
        margin: 0 auto;
    }

    .sign-in-btn {
        text-align: center;
        margin-bottom: 8px;
    }

    .forgot-password {
        position: inherit;
    }

    .form-model {
        min-height: auto;
    }

    .auth-buttons {
        text-align: left;
    }

    .home-left-banner {
        display: block;
    }

    .box-count ul li {
        width: 24%;
        text-align: center;
        margin: 1px;
        padding: 0;
    }

    .icon-form {
        display: none;
    }

    .count-div {
        margin-top: 10px;
    }

    .count-div ul li {
        min-height: auto;
    }

    .increment-container,
    .decrement-container {
        font-size: 10px;
    }

    .form-model.change-pass h1 {
        font-size: 29px;
    }

    .change-pass form.inn-form {
        margin-top: 26px;
    }

    .box-count .input-group input {
        width: 11px !important;
        font-size: 11px;
    }
    .box-count li .input-group p {
        font-size: 14px;
    }
    .box-count ul li h5 {
        font-size: 9px;
        text-align: left;
    }
    .count-div img{
        width: 55px;
    }
    .count-div .fa-times {
        font-size: 13px;
    }
    .option-in .react-datepicker__input-container input {
        padding: 8px 0;
        font-size: 12px;
    }
    .dry-reefer-select ul {
        border-radius: 0;
        display: inline-block;
        border: 1px solid #ddd;
        padding: 0;
        float: none;
    }
    .container-fluid.co-p {
        margin: 71px auto 0;
    }
    .menu-full .signup-btn i.i-new {
        margin-top: -6px;
    }
    .menu-full .signup-btn i {
        vertical-align: middle;
    }
    .menu-full .signup-btn {
        font-size: 12px;
        padding: 5px;
        min-width: 59px;
        /* display: none; */
    }
    .menu-full .signup-btn span {
        font-weight: normal;
        padding: 0 2px;
    }
    .dry-reefer-select ul li a {
        padding: 7px;
        border-radius: 0;
        font-size: 8px;
        width: auto;
        float: none;
        letter-spacing: normal;
        display: inherit;
    }
    .dry-reefer-select ul li a.active {
        border: 0 solid #335291;
    }
    .dry-reefer-select ul li a.active.box-chng {
        border-radius:0;
        border: 0 solid #335291;
    }
    .dry-reefer-select ul li a.active.box-chng-snd {
        border-radius: 0;
        border: 0 solid #335291;
    }
    .dry-reefer-select ul li {
        display: inherit;
        float: left;
    }
    .animation-wrapper{
        display: none;
    }
    .resnone{
        display: none;
    }
    .form-model .input__field--nao {
        padding: 14px 0 0 0;
        font-size: 13px;
    }
    .form-model .input {
        margin: 0 0;
        padding-top: 16px;
    }
    .form-model ul {
        margin: 0 auto 8px;
    }
    .form-model h1 {
        font-size: 25px;
    }
    .form-model h1 span {
        font-size: 25px;
    }
    .submit-btn button {
        padding: 5px 32px;
        margin: 10px auto 0;
        font-size: 14px;
    }
    .form-model ul li {
        padding: 5px 9px;
    }
    .respopnone{
        display: none;
    }
    .search-full li:nth-child(2) {
        width: 100%;
        margin-bottom: 10px;
        border-bottom: 1px solid #cae6f2;
        padding-bottom: 7px;
    }
    .search span.span-select {
        padding: 0;
        min-height: auto;
        font-size: 12px;
    }
    .search-full li:nth-child(1) {
        width: 100%;
        margin-bottom: 10px;
        border-bottom: 1px solid #cae6f2;
        padding-bottom: 7px;
    }
    .search-full ul{
        border-top: 0;
        display: inline-block;
    }
    .search-full li:nth-child(5) {
        width: 100%;
        margin-bottom: 10px;
        border-bottom: 1px solid #cae6f2;
        border-top: 1px solid #cae6f2;
        padding-bottom: 7px;
        padding-top: 7px;
    }
    .search-full li ul.ul-in li {
        padding: 0 8px;
    }
    .search-full li ul.search-container-type.ul-in li {
        padding: 0 6px;
    }
    .search-full li ul.search-container-type.ul-in li:nth-child(3) {
       margin: 0;
    }
  
    .search-full li {
        display: inline-block;
        padding: 0 0;
        border-left: 0 solid #e7f6fd;
        text-align: left;
    }
    .search-full li:nth-child(3) {
        width: 48%;
        margin-right: 5px;
    }
    .search-full li:nth-child(4) {
        width: 48%;
        margin-left: 5px;
    }
    .search-full li:nth-child(6) {
        width: 100%;
        border-bottom: 1px solid #cae6f2;
        padding-bottom: 7px;
    }
    .search-full li ul.ul-in li:nth-child(2) {
        border-bottom: 0;
        margin-bottom: 0;
    }
    .search-full li ul.ul-in li:nth-child(1) {
        border-bottom: 0;
        margin-bottom: 0;
    }
    .search-full li:nth-child(7) {
        width: 100%;
        text-align: center;
    }
    .search-full li.modifysearchbtn button {
        padding: 5px 24px;
        text-align: center;
        width: auto;
    }
    .date-change {
        width: 100%;
        height: auto;
    }
    .menu-full i {
        font-size: 13px;
    }
    .counter-bg .full-screen-modal .form-control:disabled, .counter-bg .form-control[readonly] {
        background-color: #f1f1f157;
        border: 0 solid #e8e8e8;
    }
    .search-full .select.select-full .css-1hwfws3 {
        font-size: 12px;
        padding: 0 0;
    }
    .search-full  .css-yk16xz-control {
        min-height: auto;
    }
    .search-full .css-tlfecz-indicatorContainer {
        padding: 0 0;
    }
    .date-change .react-datepicker__input-container input.search-datepicker {
        padding: 0 0;
        font-size: 12px;
    }
    .input-tab {
        height: auto;
    }
    .css-b8ldur-Input {
        margin: 0;
        padding-bottom: 0;
        padding-top: 0;
    }
    .search-full {
        padding: 10px;
    }
    .routes-found li:nth-child(1) {
        font-size: 11px;
    }
    .routes-found li {
        font-size: 12px;
        padding: 10px 8px 0 0;
    }
    .grow-booking {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .routes-found ul {
        display: inline-block;
    }
    .modify-container-type.ul-in span.qty {
        margin-right: 3px;
    }
    .footer-last {
        position: inherit;
        text-align: center;
    }
    .footer-last p {
        font-size: 11px;
    }
    .footer-last ul {
        float: none;
    }
    .footer-last ul li a {
        font-size: 11px;
    }
    .confirm-booking-inn-box b {
        font-size: 10px;
        font-weight: 600;
    }
    .confirm-booking-inn-box h1 {
        font-size: 13px;
        min-height: auto;
    }
    .confirm-booking-inn-box h5 {
        margin-bottom: 0;
    }
    .confirm-booking-inn-box{
        text-align: center;
    }
    .confirm-booking-inn-box-img p {
        margin-bottom: 0;
    }
    .confirm-booking-inn-box-img {
        padding: 15px 0;
    }
    .new-box-port {
        margin-bottom: 25px;
        text-align: center;
    }
    .view-price-breakdown-newconfirm-box span {
        font-size: 10px;
    }
    .confirm-booking-new-table table th:nth-child(1) {
        width: 24%;
    }
    .confirm-booking-new-table table td:nth-child(3) input {
        width: 100%;
        font-size: 12px;
        padding: 0;
    }
    .confirm-booking-new-table table td:nth-child(2) {
        width: 34%;
    }
    .confirm-booking-new-table table th {
        font-size: 10px;
        padding: 5px 5px;
    }
    .confirm-booking-new-table .counter-bg i {
        font-size: 12px;
    }
    .confirm-booking-new-table .counter-bg input {
        width: 34px !important;
        font-size: 12px;
    }
    .confirm-booking-new-table table td {
        padding: 5px 5px;
    }
    .confirm-booking-new-table span {
        font-size: 12px;
        margin: 0 5px 0 5px;
    }
    .confirm-booking-new-table table td:nth-child(1) {
        font-weight: 600;
        font-size: 14px;
    }
    .total-charges-newconfirm-box li:nth-child(2) {
        padding: 14px 19px 0 18px;
    }
    .new-change-confirm-booking-full {
        margin-top: 15px;
        margin-bottom: 30px;
    }
    .confirm-booking-new-table {
        margin-top: 20px;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .new-cargo-contains-hazardous {
        margin-top: 00px;
    }
    .new-cargo-contains-hazardous .container-lable {
        padding-top: 0;
        font-size: 13px;
    }
    .cargo-line-hazardous-btn {
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .new-class-tr-div-packing-group.btnfull-freight-terms {
        margin-top: 0;
    }
    .new-class-tr-div-packing-group p {
        margin-top: 0;
        margin-bottom: 7px;
    }
    .new-describe-your-cargo-full {
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .new-class-tr-div-packing-group.btnfull-freight-terms .form-group {
        margin-bottom: 0;
    }
    .note-approvals {
        margin-top: 0;
    }
    .note-approvals b {
        font-size: 12px;
        text-align: center;
    }
    .new-describe-your-box label {
        font-size: 13px;
        text-align: center;
    }
    .note-approvals p {
        font-size: 12px;
        margin-top: 0;
        text-align: center;
    }
    .btn-confirm-new p {
        margin-bottom: 15px;
        margin-top: 0;
    }
    .btn-confirm-new ul li button {
        padding: 10px 21px;
        font-size: 14px;
    }
    .new-describe-your-box label span {
        font-size: 13px;
    }
    .btn-confirm-new {
        padding-bottom: 0;
    }
    .your-booking-quote-full {
        width: 100%;
    }
    .booking-includes{
        min-height: auto;
         padding: 19px 11px;
    }
    .booking-includes li span {
        font-size: 12px;
    }
    .booking-includes.booking-excludes li span {
        font-size: 12px;
    }
    .booking-includes li {
        font-size: 14px;
    }
    .new-un-number p {
        margin-bottom: 2px;
    }
    .history-nev ul li a {
        padding: 5px 10px;
        font-size: 11px;
    }
    img.pending-icon {
        width: 10px;
        margin-top: -2px;
    }
    .user-side-nav.booking-left-address {
        margin-bottom: 00px;
    }
    .add-line-bg h1 {
        font-size: 14px;
    }
    .profile-all .upload-2-files .btn {
        margin: 4px 0 0;
    }
    .heading-logo {
        margin: 0 10px;
        width: 92%;
    }
    .heading-logo h1 {
        font-size: 13px;
    }
    .mob-fg-logo {        
        width: auto;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: flex-start;        
    }
    .login-section {
        padding: 0px;
    }
    .logi-register-all {
        margin: 0 0 50px 0;
    }
    .register-section {
        margin: 50px 0 0 0;
    }
    .forget-login-psss {
        text-align: inherit;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .forget-login-psss a {
        text-align: inherit;
    }
    .forget-login-psss .btn-primary {
        padding: 10px 50px;
        margin: 0;
        text-align: inherit;
    }
    .search-input-file-cng button {
        padding: 6px 8px;
    }
    .bl-btn ul {
        text-align: inherit;
    }
    .cro-dive ul.cro-dive-box-view-date {
        display: flex;
        flex-direction: column;
    }
    .cro-dive ul.cro-dive-box-view-date li {
        padding: 0;
        border-right: none;
    }
    .booking-history-heading {
        display: flex;
        flex-direction: column;
        margin-top: 0;        
    }
    .booking-history-heading h6 {
        margin: 0 0 5px 0;
    }
    .mob-confirm-booking {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        border-bottom: 1px solid #c4d2d7;
        margin: 0 0 12px 0;
    }
    .head-confirm-booking h3 {
        font-size: 11px;
        text-transform: uppercase;
        color: #000;
        font-family: Rubik,sans-serif;
    }
    .head-confirm-booking span {
        font-size: 11px;
        color: #335291;
        text-transform: uppercase;
        font-family: Rubik,sans-serif;
        line-height: 14px;
        display: block;
        padding: 0 0 5px 0;
    }
    p.port-name-chng {
        font-weight: 500;
        margin-bottom: 6px;
        font-size: 14px;
        color: #335291;
        font-family: Rubik,sans-serif;
        line-height: 18px;
    }
    .full-list-booking {
        padding: 0;
    }
    .booking-rate-dtl {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .booking-rate-dtl h3 {
        font-size: 12px;
        font-weight: 500;
        color: #000;
    }
    .booking-rate-dtl p {
        font-size: 12px;
        color: #335291;
        font-weight: normal;
        margin: 0;
    }
    .vgm-div-box-right ul {
        text-align: center;
    }
    td.goods_description-one {
        width: 50% !important;
        padding: 0 !important;
        font-size: 14px;
    }
    .cro-dive-box-details.new-mt {
        margin-top: 14px;
    }
    .modal-dialog.price-breackdown-popup button.close {
        top: 17px;
    }
    .close {
        font-size: 20px;
        font-weight: 600;
        text-shadow: transparent;
    }
    .booking-history-heading.no-booking a.no_booking_btn_click_here {
        width: 50%;
        margin: 0 auto;
    }
    .search-input-file-cng .css-yk16xz-control {
        margin-bottom: 10px;
    }
    .enquiry-view-head {
        font-size: 14px;
    }
    .loading-text {
        font-size: 30px;
    }
    .login-section .btn-primary {
        padding: 8px 50px;
    }
    .booking-day p {
        font-size: 12px;
        color: #26a1d5;
        font-weight: 500;
        text-align: center;
    }
    .confirm-booking-table p {
        font-size: 16px;
        color: #335291;
        margin: 5px 0 0 0;
    }
    .cro-dive-box h2 {
        margin: 0;
    }
    .confirm-booking-new-table-date .react-datepicker-wrapper {
        margin: 0 0 10px 0;
    }
    .upload-2-files {
        margin: 0 auto;
    }
    .remark {
        margin-top: 10px;
        margin-bottom: 0;
    }
    .static-data-input {
        margin-bottom: 15px;
    }
    .div-static-data-full.new-blfrom label {
        margin-bottom: 2px;
    }
  
}



@media (min-width: 768px) and (max-width: 991.98px) {
    body {
        /* margin-top: 63px; */
        margin-top: 0;
    }
    .history-nev ul li a {
        padding: 10px 17px;
        font-size: 12px;
    }
    .fixed-top {
        position: fixed;
    }

    .banner-tab-view .tab-content {
        width: 100%;
    }

    .menu-full .navbar-brand img {
        /*width: 75px;*/
    }

    .menu-full .signup-btn {
        font-size: 12px;
        padding: 4px 11px;
        min-width: auto;
        display: none;
    }

    .menu-full ul {
        margin-top: 0;
        margin-left: 0;
    }

    .menu-full .navbar-light .navbar-nav .nav-link {
        font-size: 12px;
        padding: 0 8px;
    }

    .icon-form img {
        width: 100%;
    }

    .option-in .css-1wa3eu0-placeholder {
        font-size: 13px;
    }

    .option-in .react-date-picker__inputGroup {
        font-size: 13px;
    }

    /* body {
        margin-top: 96px;
    } */
    .banner-left-text ul li {
        margin: 6px 0;
        font-size: 12px;
        width: 100%;
    }

    .stats span {
        font-size: 24px;
    }

    .banner-left-text h1 {
        font-size: 28px;
    }

    .container-fluid.co-p {
        padding: 00px 17px 0 17px;
        margin: 78px auto 0;
    }

    .box-count ul li h5 {
        font-size: 12px;
    }

    .option-form {
        margin-top: 20px;
    }
    .resnone{
        display: none;
    }
    .dry-reefer-select ul li a {
        padding: 5px 7px;
        font-size: 11px;
    }
    .box-count ul li {
        margin: 2px;
        padding: 6px 0;
        width: 24%;
    }
    .option-in .react-datepicker__input-container input {
        padding: 9px 6px;
        font-size: 13px;
    }
    .search-btn a {
        padding: 7px 30px;
        font-size: 14px;
    }
    .respopnone {
        display: none;
    }
    .total-charges-newconfirm-box li:nth-child(2) {
        padding: 14px 6px 0 6px;
        font-size: 13px;
    }
    .total-charges-newconfirm-box b {
        font-size: 14px;
    }
    .total-charges-newconfirm-box b span {
        font-size: 12px;
    }
    .total-charges-newconfirm-box p {
        font-size: 10px;
    }
    .confirm-booking-inn-box b {
        font-size: 11px;
    }
    .confirm-booking-inn-box h1 {
        font-size: 15px;
    }
    .confirm-booking-new-table table th:nth-child(1) {
        width: 49%;
    }
    .new-class-tr-div-packing-group .custom-control-inline {
        margin-right: 3px;
        padding-left: 17px;
    }
    .new-class-tr-div-packing-group .custom-control-label {
        font-size: 13px;
    }
    .your-booking-quote-full {
        width: 100%;
    }
    .booking-includes li {
        font-size: 15px;
    }
    .upload-2-files .btn {
        padding: 8px 14px;
        font-size: 11px;
    }
    .logi-register-all {
        margin: 0 0 50px;
    }
    .mob-fg-logo {
        width: auto;
        /*height: 95px;*/
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .heading-logo {
        margin: 0 10px;
        width: 92%;
    }
    .heading-logo h1 {
        font-size: 22px;
    }  
    .login-section {
        padding: 0px;
    }
    .logi-register-all h5 {
        font-size: 20px;
    }
    .forget-login-psss {
        text-align: inherit;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .forget-login-psss a {
        text-align: inherit;
    }
    .forget-login-psss .btn-primary {
        padding: 10px 50px;
        margin: 0;
        text-align: inherit;
    }
    .search-input-file-cng input {
        width: 87%;
        padding: 6px 8px;
        font-size: 13px;
    }
    .search-input-file-cng button {
        padding: 6px 9px;
        font-size: 13px;
    }
    .search-input-file-cng .css-yk16xz-control {
        padding: 0;
    }
    .css-yk16xz-control {
        border-radius: 0 !important;
        font-size: 13px;
    }
    .booking-history-heading {
        display: flex;
        flex-direction: column;
        margin-top: 0;        
    }
    .booking-history-heading h6 {
        margin: 0 0 5px 0;
    }
	.mob-confirm-booking {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        border-bottom: 1px solid #c4d2d7;
        margin: 0 0 12px 0;
    }
    .head-confirm-booking h3 {
        font-size: 11px;
        text-transform: uppercase;
        color: #000;
        font-family: Rubik,sans-serif;
    }
    .head-confirm-booking span {
        font-size: 11px;
        color: #335291;
        text-transform: uppercase;
        font-family: Rubik,sans-serif;
        line-height: 14px;
        display: block;
        padding: 0 0 5px 0;
    }
    p.port-name-chng {
        font-weight: 500;
        margin-bottom: 6px;
        font-size: 14px;
        color: #335291;
        font-family: Rubik,sans-serif;
        line-height: 18px;
    }
    .full-list-booking {
        padding: 0;
    }
    .booking-rate-dtl {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .booking-rate-dtl h3 {
        font-size: 12px;
        font-weight: 500;
        color: #000;
    }
    .booking-rate-dtl p {
        font-size: 12px;
        color: #335291;
        font-weight: normal;
        margin: 0;
    }
    .vgm-div-box-right ul {
        text-align: center;
    }
    .cro-dive-box-details b {
        font-size: 13px;
        line-height: 16px;
        display: block;
        padding: 5px 0 0 0;
    }
    .cro-dive ul.cro-dive-box-view-date li {
        padding: 0 12px;
    }
    td.goods_description-one {
        width: 45% !important;
    }
    .bl-btn ul {
        text-align: inherit;
    }
    .enquiry-add-btn {
        margin-top: 0;
    }
    .serach-box-booking {
        margin-top: 0;
    }
    .loading-text {
        font-size: 40px;
    }
}


@media (min-width: 992px) and (max-width: 1024.98px) {
    .fixed-top {
        position: fixed;
    }
    body {
        margin-top: 0;
    }
    .history-nev ul li a {
        padding: 10px 17px;
        font-size: 12px;
    }
    .banner-tab-view .tab-content {
        width: 100%;
    }

    .menu-full ul {
        margin-left: 0;
    }

    .menu-full .navbar-light .navbar-nav .nav-link {
        padding: 0 17px;
        
    }

    .menu-full .navbar-brand img {
        width: 30%;
    }

    .banner-left-text ul li {
        width: 100%;
        font-size: 14px;
    }

    .banner-left-text h1 {
        font-size: 38px;
    }

    .stats span {
        font-size: 31px;
    }

    .boat-wave-in .plane-body {
        background-size: 198px;
        background-repeat: no-repeat;
    }

    .boat-wave-in .plane {
        top: 8%;
    }

    .container-fluid.co-p {
        padding: 90px 16px 0 16px;
        margin: 0 0 0 0;
    }

    .box-count ul li h5 {
        font-size: 12px;
    }

    .option-form {
        margin-top: 20px;
    }

    .dry-reefer-select {
        margin: 10px auto 0;
    }

    .box-count li .input-group p {
        font-size: 16px;
    }

    .upload-2-files .btn {
        padding: 8px 10px;
        font-size: 11px;
    }
    .total-charges-newconfirm-box p {
        font-size: 10px;
    }
    .total-charges-newconfirm-box li:nth-child(2) {
        padding: 14px 8px 0 8px;
        font-size: 13px;
    }
    .total-charges-newconfirm-box b {
        font-size: 12px;
    }
    .total-charges-newconfirm-box b span {
        font-size: 12px;
    }
    .confirm-booking-inn-box h1 {
        font-size: 15px;
        min-height: 50px;
    }
    .confirm-booking-inn-box b {
        font-size: 11px;
    }
    .confirm-booking-new-table table th:nth-child(1) {
        width: 49%;
    }
    .new-describe-your-box label span {
        font-size: 14px;
    }
    .note-approvals p {
        font-size: 13px;
    }
    .booking-includes li {
        font-size: 14px;
    }
    .booking-includes.booking-excludes li span {
        font-size: 13px;
    }
    .fotter-no-re{
        display: none;
    }
    .user-side-nav.booking-left-address .loading-text {
        font-size: 23px;
    }
    .booking-left-address h6 {
        font-size: 12px;
    }
    .login-section {
        padding: 20px;
    }
    .forget-login-psss {
        text-align: inherit;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .forget-login-psss a {
        text-align: inherit;
    }
    .forget-login-psss .btn-primary {
        padding: 10px 50px;
        margin: 0;
        text-align: inherit;
    }
    .search-input-file-cng input {
        width: 86%;
    }
    .booking-history-heading {
        display: flex;
        flex-direction: column;
        margin-top: 0;        
    }
    .booking-history-heading h6 {
        margin: 0 0 5px 0;
    }
	.mob-confirm-booking {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        border-bottom: 1px solid #c4d2d7;
        margin: 0 0 12px 0;
    }
    .head-confirm-booking h3 {
        font-size: 16px;
        text-transform: uppercase;
        color: #000;
        font-family: Rubik,sans-serif;
    }
    .head-confirm-booking span {
        font-size: 14px;
        color: #335291;
        text-transform: uppercase;
        font-family: Rubik,sans-serif;
        line-height: 14px;
        display: block;
        padding: 0 0 5px 0;
    }
    p.port-name-chng {
        font-weight: 500;
        margin-bottom: 6px;
        font-size: 16px;
        color: #335291;
        font-family: Rubik,sans-serif;
        line-height: 18px;
    }
    .full-list-booking {
        padding: 0;
    }
    .booking-rate-dtl {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .booking-rate-dtl h3 {
        font-size: 12px;
        font-weight: 500;
        color: #000;
    }
    .booking-rate-dtl p {
        font-size: 12px;
        color: #335291;
        font-weight: normal;
        margin: 0;
    }
    .vgm-div-box-right ul {
        text-align: center;
    }
    .cro-dive-box-details b {
        font-size: 13px;
        line-height: 16px;
        display: block;
        padding: 5px 0 0 0;
    }
    td.goods_description-one {
        width: 30% !important;
    }
    .cro-dive ul.cro-dive-box-view-date li {
        padding: 0 10px;
    }
    .modal-dialog.price-breackdown-popup {
        max-width: 70%;
    }
}


@media (min-width: 1025px) and (max-width: 1199.98px) {
    .fixed-top {
        position: fixed;
    }
    body {
        margin-top: 0;
        padding-top: 100px;
    }
    .history-nev ul li a {
        padding: 10px 17px;
        font-size: 12px;
    }
    .banner-tab-view .tab-content {
        width: 100%;
    }

    .menu-full ul {
        margin-left: 0;
    }

    .menu-full .navbar-light .navbar-nav .nav-link {
        padding: 0 17px;
        
    }

    .menu-full .navbar-brand img {
        width: 30%;
    }

    /* body{
        margin-top: 100px;
    } */
    .banner-left-text ul li {
        width: 100%;
        font-size: 14px;
    }

    .banner-left-text h1 {
        font-size: 38px;
    }

    .stats span {
        font-size: 31px;
    }

    .boat-wave-in .plane-body {
        background-size: 198px;
        background-repeat: no-repeat;
    }

    .boat-wave-in .plane {
        top: 8%;
    }

    .container-fluid.co-p {
        padding: 90px 16px 0 16px;
        margin: 0 0 0 0;
    }

    .box-count ul li h5 {
        font-size: 12px;
    }

    .option-form {
        margin-top: 20px;
    }

    .dry-reefer-select {
        margin: 10px auto 0;
    }

    .box-count li .input-group p {
        font-size: 16px;
    }

    .upload-2-files .btn {
        padding: 8px 10px;
        font-size: 11px;
    }
    .total-charges-newconfirm-box p {
        font-size: 10px;
    }
    .total-charges-newconfirm-box li:nth-child(2) {
        padding: 14px 8px 0 8px;
        font-size: 13px;
    }
    .total-charges-newconfirm-box b {
        font-size: 12px;
    }
    .total-charges-newconfirm-box b span {
        font-size: 12px;
    }
    .confirm-booking-inn-box h1 {
        font-size: 15px;
        min-height: 50px;
    }
    .confirm-booking-inn-box b {
        font-size: 11px;
    }
    .confirm-booking-new-table table th:nth-child(1) {
        width: 49%;
    }
    .new-describe-your-box label span {
        font-size: 14px;
    }
    .note-approvals p {
        font-size: 13px;
    }
    .booking-includes li {
        font-size: 14px;
    }
    .booking-includes.booking-excludes li span {
        font-size: 13px;
    }
    .fotter-no-re{
    display: none;
    }
    .user-side-nav.booking-left-address .loading-text {
        font-size: 23px;
    }
    .booking-left-address h6 {
        font-size: 12px;
    }

}

@media (min-width: 1200px) and (max-width: 1438.99px) {
    .banner-tab-view .tab-content {
        width: 100% !important;
    }

    .banner-left-text h1 {
        font-size: 41px;
    }

    .banner-left-text ul li {
        font-size: 11px;
    }

    .routes-found li {
        font-size: 12px;
        padding: 10px 7px;
    }

    .main-listing-right td.shiping-name p {
        font-size: 13px;
    }

    .main-listing-right .table td,
    .main-listing-right .table th {
        padding: 7px 8px;
    }

    .main-listing-right tr.bg-tr-change td li {
        font-size: 9px;
        color: #71757f;
        padding: 0 5px;
    }

    .main-listing-right tr th:nth-child(1) {
        width: 351px;
    }

    .main-listing-right tr th:nth-child(2) {
        width: 81px;
    }

    .main-listing-right tr th:nth-child(3) {
        width: 124px;
    }
    .main-listing-right tr th.changes-new:nth-child(3){
        width: 42px;
    }
    .main-listing-right tr th:nth-child(4) {
        width: 113px;
    }

    .main-listing-right a.price-drop {
        font-size: 11px;
    }

    .main-listing-right td li {
        font-size: 12px;
    }

    .bg-tr-change li p {
        font-size: 10px;
    }

    .routes-found li:nth-child(4) {
        margin-top: -2px;
    }

    .menu-full i {
        font-size: 10px;
    }

    .modify-container-type.ul-in span.qty {
        margin-right: 0;
    }

    .search .react-date-picker__button {
        padding: 4px 3px;
    }

    .menu-full a.login {
        margin-right: 13px;
    }

    .confirm-booking-table table {
        width: 87%;
    }

    .your-booking-quote-full {
        width: 87%;
    }

    .water ul.waves li.wave-one {
        height: 17px;
    }

    .water ul.waves li.wave-two {
        height: 40px;
    }

    .water ul.waves li.wave-three {
        height: 50px;
    }

    .water ul.waves li.wave-four {
        height: 60px;
    }

    .boatwave {
        bottom: -12px;
    }

    .stats span {
        font-size: 22px;
    }

    #counter-stats h5 {
        font-size: 11px;
    }

    .counting {
        font-size: 15px;
    }

    .confirm-booking-table p {
        font-size: 14px;
    }

    .table-booking-list .booking-date-table td {
        font-size: 9px;
    }

    .table-booking-list .booking-date-table td.date-right {
        font-size: 9px;
    }

    .table-booking-list .booking-date-table td.date-right b {
        font-size: 9px;
    }

    td.total b {
        font-size: 12px;
    }

    .table-booking-list table td {
        padding: 6px 6px;
    }

    .confirm-booking-table td.bookoing-right-table {
        padding: 0 !important;
    }

    .table-booking-list .confirm-booking-table td:nth-child(4) b {
        padding-left: 1px;
    }

    .booing-status ul li {
        padding-right: 10px;
    }

    .table-booking-list .table-booking-inn td:nth-child(2) {
        width: auto;
    }

    .table-booking-list .table-booking-inn td.hozara-dous {
        font-size: 14px;
    }

    .table-booking-list .table-booking-inn td.discount {
        font-size: 14px;
    }

    .table-booking-list .table-booking-inn td.ft-count {
        font-size: 12px;
    }

    .table-booking-list .table-booking-inn td.ft-count {
        width: auto;
    }

    .table-booking-list .table-booking-inn td.hozara-dous {
        width: auto;
    }

    td.class-select b {
        font-size: 16px;
    }

    td.class-select {
        padding: 6px !important;
    }

    .tr-new-table .table-booking-inn tr td {
        font-size: 11px;
    }

    .table-booking-list .confirm-booking-table td.total-amount b {
        font-size: 14px;
    }

    .cro-dive ul.cro-dive-box-view-date li b {
        font-size: 10px;
        margin-left: 4px;
    }

    .cro-dive ul.cro-dive-box-view-date li {
        font-size: 11px;
        padding: 0 8px;
    }

    .documents ul a {
        padding: 8px 12px;
    }

    .vgm-div-box-left h2 {
        font-size: 14px;
    }

    /*.vgm-div-box-right a {*/
    /*    padding: 8px 24px;*/
    /*}*/
    .serach-box-booking {
        padding: 8px;
    }

    .booking-bl h3 b {
        font-size: 11px;
    }

    .booking-bl h3 {
        font-size: 10px;
    }

    .booking-bl {
        padding: 6px;
    }

    .booking-bl .booking-name ul li p {
        font-size: 9px;
        margin-right: 1px;
    }

    .booking-bl .booking-name ul li b {
        font-size: 8px;
        font-weight: 600;
    }

    .booking-bl b {
        font-size: 12px;
        font-weight: 500;
    }

    .booking-bl span {
        font-size: 8px;
    }

    .cro-dive.shipping-bill .vgm-div-box-right button {
        padding: 10px 28px;
    }

    td.goods_description-one {
        padding: 0 5px 0 0 !important;
    }

    td.goods_description {
        padding: 0 !important;
    }

    .booing-status ul li b.issued-color {
        font-size: 12px;
    }

    .booing-status ul li b {
        font-size: 12px;
    }

    .booing-status ul li p {
        font-size: 10px;
    }

    .bl-btn ul li button {
        padding: 6px 10px;
        font-size: 11px;
    }

    .bl-btn ul li button {
        margin: 4px 3px;
    }

    .search-input-file-cng input {
        width: 88%;
    }

    .container-fluid.co-p {
        margin: 29px auto 0;
    }

    .box-count ul li {
        padding: 4px 4px;
    }

    .box-count ul li h5 {
        font-size: 13px;
    }

    .option-form {
        margin-top: 20px;
    }

    .box-count .dry-reefer-select ul {
        margin: 4px auto 0;
    }

    .box-count li .input-group p {
        font-size: 16px;
    }

    .booking-highlight p {
        font-size: 9px;
    }

    .table-div table th {
        font-size: 10px;
    }

    .form-modelnew h5 {
        line-height: 16px;
        font-size: 12px;
    }

    .form-modelnew {
        margin-bottom: 12px;
    }

    .form-modelnew-inn {
        margin-bottom: 12px;
    }

    .form-modelnew-inn label {
        margin-bottom: 5px;
    }

    .div-popup-all {
        min-height: 415px;
        height: 415px;
    }

    .pop-up-loginnew h5 {
        font-size: 14px;
    }

    .total-charges-newconfirm-box li:nth-child(2) {
        padding: 14px 7px 0 7px;
        font-size: 10px;
    }

    .total-charges-newconfirm-box b {
        font-size: 17px;
    }

    .total-charges-newconfirm-box b span {
        font-size: 10px;
    }

    .confirm-booking-new-table table th:nth-child(1) {
        width: 54%;
    }

    .new-class-tr-div-packing-group .container-lable {
        padding-left: 25px;
        font-size: 12px;
    }

    .btn-confirm-new ul li button {
        padding: 12px 30px;
        font-size: 14px;
    }

    .view-price-breakdown-newconfirm-box button {
        padding: 6px 17px;
    }

    .search-full li:nth-child(1) {
        width: 13%;
    }

    .search-full li {
        padding: 0 3px;
    }

    .search-full li ul.ul-in li {
        padding: 0 6px;
    }

    .search span.span-select {
        font-size: 12px;
    }

    .modal-dialog.price-breackdown-popup {
        max-width: 79%;
    }

    .grow-booking h4 {
        font-size: 18px;
    }

    .grow-booking button {
        font-size: 12px;
    }

    .enquiry-form-page label {
        font-size: 12px;
    }

    .error-t-negative.cargo-weight.form-group {
        width: 84%;
    }

    .error-t-negative.cargo-weight-value {
        width: 16%;
    }

    .confirm-booking-table td {
        padding: 3px !important;
    }

    .view-price-breakdown-newconfirm-box span {
        font-size: 9px;
    }

    .upload-2-files .btn {
        padding: 8px 14px;
    }
    .total-charges-newconfirm-box b span {
        font-size: 13px;
    }
    .total-charges-newconfirm-box b {
        font-size: 17px;
    }
}

@media (min-width: 1440px) and (max-width: 1600px) {
    .table-div table th {
        font-size: 13px;
    }

    .form-modelnew h5 {
        line-height: 25px;
        font-size: 15px;
    }

    .form-modelnew {
        margin-bottom: 12px;
    }

    .form-modelnew-inn {
        margin-bottom: 12px;
    }

    .form-modelnew-inn label {
        margin-bottom: 5px;
    }

    .div-popup-all {
        min-height: 418px;
        height: 418px;
    }

    .pop-up-loginnew h5 {
        font-size: 15px;
    }

    .modal-dialog.price-breackdown-popup {
        max-width: 66%;
    }
}